import React from 'react';

import {
  SectionJobText,
  SectionJob,
  SectionText,
} from './SectionJobText.styled';

export default ({
  className,
  sectionJob,
  hasSectionJobDecoration = false,
  sectionText,
}) => (
  <SectionJobText className={className}>
    {sectionJob && (
      <SectionJob
        content={sectionJob?.content}
        contentTextAlign={sectionJob?.contentTextAlign}
        tilesTitle={sectionJob?.title}
        hasDecoration={hasSectionJobDecoration}
      />
    )}
    {sectionText && (
      <SectionText
        content={sectionText?.content}
        themeName={sectionText?.themeName}
        decoration={sectionText?.decoration}
      />
    )}
  </SectionJobText>
);
