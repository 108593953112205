import styled from '@emotion/styled';

export const H1 = styled('h1')(
  {
    wordBreak: 'break-word',
    fontSize: 42,
    lineHeight: 1.4,
    letterSpacing: 0.3,
    fontWeight: 700,
  },
  props => ({
    fontFamily: props.theme.fontSecondary,
    color: props.theme.headlineH1Color,
  }),
);

export const H2 = styled('h2')(
  {
    wordBreak: 'break-word',
    fontSize: 32,
    lineHeight: 1.25,
    letterSpacing: 0.3,
    fontWeight: 700,
  },
  props => ({
    fontFamily: props.theme.fontSecondary,
    color: props.theme.headlineH2Color,
  }),
);

export const H3 = styled('h3')(
  {
    wordBreak: 'break-word',
    fontSize: 26,
    lineHeight: 1.4,
    letterSpacing: 0.8,
    fontWeight: 700,
  },
  props => ({
    fontFamily: props.theme.fontSecondary,
    color: props.theme.headlineH3Color,
  }),
);

export const H4 = styled('h4')(
  {
    wordBreak: 'break-word',
    fontSize: 20,
    lineHeight: 1.4,
    letterSpacing: 0.3,
    fontWeight: 700,
  },
  props => ({
    fontFamily: props.theme.fontSecondary,
    color: props.theme.headlineH4Color,
  }),
);

export const H5 = styled('h5')(
  {
    wordBreak: 'break-word',
    fontSize: 16,
    lineHeight: 1.4,
    letterSpacing: 1.6,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  props => ({
    fontFamily: props.theme.fontSecondary,
    color: props.theme.headlineH5Color,
  }),
);

export const H6 = styled('h6')(
  {
    wordBreak: 'break-word',
    fontSize: 16,
    lineHeight: 1,
    fontWeight: 700,
  },
  props => ({
    fontFamily: props.theme.fontPrimary,
    color: props.theme.headlineH6Color,
  }),
);
