import React from 'react';

import { useScrollPosition, useFirstSectionTheme } from 'utils/hooks';

import LayoutNav from 'components/LayoutNav';
import SwitchTheme from 'components/SwitchTheme';

import { SectionHeader, Flex, Wrap, Box, Logo } from './SectionHeader.styled';

export default ({ hasNavigation }) => {
  const firstSectionThemeName = useFirstSectionTheme();
  const scrollPosition = useScrollPosition();
  const isTop = scrollPosition < 24;

  return (
    <SwitchTheme themeName={!isTop ? 'bright' : firstSectionThemeName}>
      <SectionHeader sticky={!isTop}>
        <Wrap>
          <Flex alignItems="flex-end" sticky={!isTop}>
            <Box width={[1]}>
              <Flex justifyContent="space-between" alignItems="center">
                <Box>
                  <Logo sticky={!isTop} />
                </Box>
                <Box>
                  {hasNavigation && <LayoutNav menuName="navigationPrimary" />}
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Wrap>
      </SectionHeader>
    </SwitchTheme>
  );
};
