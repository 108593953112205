import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { getMenuNodeByName, getLinks } from 'utils/sanity';

import LayoutNavSidebar from 'components/LayoutNavSidebar';
import { LayoutNoticeContext } from 'components/LayoutNotice';

import {
  LayoutNav,
  NavItem,
  NavItemBurger,
  BurgerIcon,
} from './LayoutNav.styled';

export default ({ className, menuName = '' }) => {
  const data = useStaticQuery(graphql`
    {
      allSanityMenu {
        edges {
          node {
            name
            menuImage: _rawMenuImage
            menuItem {
              menuItemType {
                __typename
                ... on SanityMenuItemStatic {
                  _key
                  title
                  linkStatic {
                    _key
                    _type
                    href
                    blank
                  }
                }
                ... on SanityMenuItemReference {
                  _key
                  title
                  linkReference {
                    _type
                    reference {
                      ... on SanityJob {
                        page {
                          slug {
                            current
                          }
                        }
                      }
                      ... on SanityPage {
                        slug {
                          current
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const notice = useContext(LayoutNoticeContext);
  const menu = getMenuNodeByName(data, menuName);
  const menuItems = menu?.menuItem?.menuItemType;
  const menuImage = menu?.menuImage;

  console.log(menuItems);

  return menuItems.length > 0 ? (
    <LayoutNav className={className}>
      {menuItems?.map(menuItem => (
        <NavItem
          key={menuItem?._key}
          to={getLinks(menuItem)?.to}
          target={getLinks(menuItem)?.blank}
        >
          {menuItem?.title}
        </NavItem>
      ))}
      <NavItemBurger
        onClick={e => {
          e.preventDefault();
          notice.setShow(true);
          notice.setCustomContent(true);
          notice.setContent(
            <LayoutNavSidebar menu={menuItems} menuImage={menuImage} />,
          );
        }}
      >
        <BurgerIcon type="hamburger" />
      </NavItemBurger>
      )
    </LayoutNav>
  ) : null;
};
