import React from 'react';
import { FaFacebookSquare, FaInstagram, FaTwitter } from 'react-icons/fa';

import { Social } from './Social.styled';

export default ({ className, url, type }) => {
  switch (type) {
    case 'SanitySocialItemFacebook':
      return (
        <Social
          ariaLabel="Facebook"
          className={className}
          to={url}
          target="_blank"
        >
          <FaFacebookSquare />
        </Social>
      );

    case 'SanitySocialItemInstagram':
      return (
        <Social
          ariaLabel="Instagram"
          className={className}
          to={url}
          target="_blank"
        >
          <FaInstagram />
        </Social>
      );

    case 'SanitySocialItemTwitter':
      return (
        <Social
          ariaLabel="Twitter"
          className={className}
          to={url}
          target="_blank"
        >
          <FaTwitter />
        </Social>
      );

    default:
      return null;
  }
};
