import styled from '@emotion/styled';

import { grid } from 'config/variables';
import { media, toPx } from 'utils/styles';

export const Wrap = styled('div')(
  {
    position: 'relative',
    margin: '0 auto',
    padding: `0 ${toPx(grid.gutter)}`,
  },
  () => ({
    // Automap grid wrap widths to create wraps, otherwise you would have to add them by hand if you change something ~DH
    ...Object.assign(
      {},
      ...Object.keys(grid.width).map((key, i) =>
        // Avoid first query by default since its mobile first ~DH
        i < 1
          ? {
              width: grid.width[key],
            }
          : {
              [media(`${key}`)]: {
                width: grid.width[key],
              },
            },
      ),
    ),
  }),
);
