import React from 'react';

import SwitchTheme from 'components/SwitchTheme';

import {
  SectionHero,
  BackgroundImage,
  Content,
  Wrap,
  Box,
  Overlay,
} from './SectionHero.styled';

export default ({ content, figure, themeName }) => (
  <SwitchTheme themeName={themeName}>
    <SectionHero>
      <BackgroundImage image={figure} />
      {themeName !== 'bright' && <Overlay />}
      <Wrap>
        <Box width={[1, null, null, 5 / 8]}>
          <Content content={content} />
        </Box>
      </Wrap>
    </SectionHero>
  </SwitchTheme>
);
