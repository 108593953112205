import { colors } from 'config/variables';

export const theme = {
  name: 'yellow',
  type: 'bright',

  // global
  background: colors.yellowPrimary,
  color: colors.yellowPrimary,

  // component Text
  textPColor: colors.greySecondary,

  // component List
  listUlColor: colors.black,
  listUlLiBulletColor: colors.black,
  listOlColor: colors.black,
  listDlColor: colors.black,

  // component Headline
  headlineH1Color: colors.black,
  headlineH2Color: colors.black,
  headlineH3Color: colors.black,
  headlineH4Color: colors.black,
  headlineH5Color: colors.black,
  headlineH6Color: colors.black,

  // component Button
  buttonSolidColor: colors.white,
  buttonSolidBackground: colors.yellowSecondary,
  buttonSolidBackgroundHover: colors.yellowPrimary,
  buttonSolidColorHover: colors.white,
  buttonSolidOutlineColor: colors.yellowSecondary,

  buttonGhostColor: colors.yellowSecondary,
  buttonGhostBorderColor: colors.yellowSecondary,
  buttonGhostBorderColorHover: colors.yellowSecondary,
  buttonGhostBorderColorActive: colors.yellowSecondary,
  buttonGhostColorHover: colors.yellowSecondary,
  buttonGhostOutlineColor: colors.yellowSecondary,

  buttonStrippedColor: colors.yellowSecondary,
  buttonStrippedColorHover: colors.yellowPrimary,
  buttonStrippedOutlineColor: colors.yellowSecondary,
};
