import React from 'react';
import { LinkClose, Icon } from './LinkClose.styled';

export default ({ to = '/', onClick, className }) => {
  return (
    <LinkClose
      ariaLabel="schließen"
      to={to}
      onClick={onClick}
      className={className}
    >
      <Icon type="close" />
    </LinkClose>
  );
};
