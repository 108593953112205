import { theme as themeBright } from './bright';
import { theme as themeDark } from './dark';
import { theme as themeGreen } from './green';
import { theme as themeBlue } from './blue';
import { theme as themeBlueSecondary } from './blueSecondary';
import { theme as themeYellow } from './yellow';
import { theme as themePink } from './pink';

export const bright = themeBright;
export const dark = themeDark;
export const green = themeGreen;
export const blue = themeBlue;
export const blueSecondary = themeBlueSecondary;
export const yellow = themeYellow;
export const pink = themePink;
