import styled from '@emotion/styled';
import { media } from 'utils/styles';

export const Socials = styled('nav')({
  marginTop: 32,
  '& > *': {
    marginRight: 48,
    '&:last-child': {
      marginRight: 0,
    },
  },
  [media('md')]: {
    marginTop: 0,
    '& > *': {
      marginRight: 52,
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
});

export const Title = styled('span')(
  {
    userSelect: 'none',
  },
  props => ({ color: props.theme.socialsTitleColor }),
);
