import styled from '@emotion/styled';
import Link from 'components/Link';
import IconComponent from 'components/Icon';

export const LinkClose = styled(Link)(props => ({
  position: 'absolute',
  top: '50%',
  right: 0,
  height: 16,
  width: 16,
  transform: 'translateY(-50%)',
  transition: 'color 75ms ease-in-out',
  color: props.theme.greyPrimary,
  '&:hover': {
    color: props.theme.greenSecondary,
  },
}));

export const Icon = styled(IconComponent)({
  display: 'flex',
  lineHeight: 0,
  height: 16,
});
