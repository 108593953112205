import { colors } from 'config/variables';

export const theme = {
  name: 'dark',
  type: 'dark',

  // global
  background: colors.black,

  // component Logo
  logoTextColor: colors.white,

  // component Text
  textPColor: colors.white,

  // component List
  listUlColor: colors.white,
  listUlLiBulletColor: colors.white,
  listOlColor: colors.white,
  listDlColor: colors.white,

  // component Headline
  headlineH1Color: colors.white,
  headlineH2Color: colors.white,
  headlineH3Color: colors.white,
  headlineH4Color: colors.white,
  headlineH5Color: colors.white,
  headlineH6Color: colors.white,

  // component Button
  buttonSolidColor: colors.black,
  buttonSolidBackground: colors.greySecondary,
  buttonSolidBackgroundHover: colors.greySecondary,
  buttonSolidColorHover: colors.black,
  buttonSolidOutlineColor: colors.greenSecondary,

  buttonGhostColor: colors.greySecondary,
  buttonGhostBorderColor: colors.greySecondary,
  buttonGhostBorderColorHover: colors.greySecondary,
  buttonGhostBorderColorActive: colors.greySecondary,
  buttonGhostColorHover: colors.greySecondary,
  buttonGhostOutlineColor: colors.greenSecondary,

  buttonStrippedColor: colors.greySecondary,
  buttonStrippedColorHover: colors.greySecondary,
  buttonStrippedOutlineColor: colors.greenSecondary,

  // socials/s
  socialsTitleColor: colors.white,
  socialColor: colors.white,
  socialColorHover: colors.greenSecondary,

  // legalMenu
  legalMenuItemColor: colors.white,
  legalMenuItemColorHover: colors.greenSecondary,

  // navigationPrimary
  navigationPrimaryColor: colors.white,
  navigationPrimaryColorHover: colors.greenSecondary,
  navigationPrimaryColorActive: colors.greenSecondary,
};
