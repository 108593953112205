import { colors } from 'config/variables';

export const theme = {
  name: 'blueSecondary',
  type: 'bright',

  // global
  background: colors.blueSecondary,

  // component Text
  textPColor: colors.bluePrimary,

  // component List
  listUlColor: colors.bluePrimary,
  listUlLiBulletColor: colors.greyPrimary,
  listOlColor: colors.bluePrimary,
  listDlColor: colors.bluePrimary,

  // component Headline
  headlineH1Color: colors.bluePrimary,
  headlineH2Color: colors.bluePrimary,
  headlineH3Color: colors.bluePrimary,
  headlineH4Color: colors.bluePrimary,
  headlineH5Color: colors.greenSecondary,
  headlineH6Color: colors.bluePrimary,
};
