export const getMenuNodeByName = (queryData, name) => {
  if (typeof queryData !== 'object' || typeof name !== 'string') return null;

  const filteredData = queryData?.allSanityMenu?.edges.filter(
    ({ node }) => node.name === name,
  );

  if (filteredData.length > 1) {
    console.warn(
      'It seems that you have two menus with the same name in your Sanity Studio make sure to be unique in naming your menus, the function picks the first menu of the data array for now!',
    );
  }

  return filteredData?.[0]?.node;
};
