import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Box, Flex } from 'rebass';

import TilePerson from 'components/TilePerson';

import { TilesPerson } from './TilesPerson.styled';

export default ({ className }) => {
  const query = useStaticQuery(graphql`
    {
      allSanityPerson {
        edges {
          node {
            _id
            name
            jobPosition
            _rawTile
          }
        }
      }
    }
  `);
  const edges = query?.allSanityPerson?.edges;

  return edges ? (
    <TilesPerson className={className}>
      <Flex flexWrap="wrap" mx={-4}>
        {edges?.map(edge => (
          <Box
            key={edge?.node?._id}
            width={[1, 1, 1 / 2, 1 / 2, 1 / 4]}
            px={4}
            pb={5}
          >
            <TilePerson
              image={edge?.node?._rawTile?.figure}
              jobPosition={edge?.node?.jobPosition}
              name={edge?.node?.name}
            />
          </Box>
        ))}
      </Flex>
    </TilesPerson>
  ) : null;
};
