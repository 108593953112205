import styled from '@emotion/styled';

import { media } from 'utils/styles';

import Section from 'components/Section';
import WrapComponent from 'components/Wrap';
import Modules from 'components/Modules';
import DecorationComponent from 'components/Decoration';

export const SectionText = styled(Section)(
  {
    display: 'flex',
  },
  props => ({
    background: props.theme.background,
  }),
);

export const Wrap = styled(WrapComponent)({
  alignSelf: 'center',
});

export const Content = styled(Modules)({
  h2: {
    marginBottom: 24,
  },
  h1: {
    marginBottom: 32,
  },
  h5: {
    marginBottom: 18,
  },
  p: {
    marginBottom: 18,
  },
  '.buttonset': {
    marginBottom: 18,
  },
});

export const Decoration = styled(DecorationComponent)({
  display: 'none',
  [media('tablet')]: {
    display: 'block',
  },
});
