import styled from '@emotion/styled';
import { media } from 'utils/styles';

export const Section = styled('section')(
  {
    position: 'relative',
    minHeight: '100vh',
  },
  props => ({
    zIndex: props.theme.indexPrimary,
    // why these values? they derive from the header and footer height, by default
    // we give them at least their padding to make space for header and footer element.
    // absolute positioned backgroundimages and so forth gonna ignore it anyway.
    // but it is extremly helpful to have one single point of truth regarding
    // section dimensions. I highly recommend to not change this and more try to
    // understand. this you will safe a lot of time. You will find the same "fix"
    // within the "sections.styled.js" component. ~DH
    paddingTop: props.theme.spaceTop,
    paddingBottom: props.theme.spaceBottom,
    [media('laptop')]: {
      [`${Section}&:first-of-type`]: {
        paddingTop: props.theme.spaceTopLaptop,
      },
      paddingBottom: props.theme.spaceBottomLaptop,
    },
  }),
);

export const Header = styled('header')(props => ({
  zIndex: props.theme.indexPrimary,
}));

export const Footer = styled('footer')(props => ({
  zIndex: props.theme.indexPrimary,
}));
