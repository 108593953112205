import styled from '@emotion/styled';

import ImageComponent from 'components/Image';

export const Figure = styled('figure')(() => ({
  margin: 0,
  padding: 0,
}));

export const Image = styled(ImageComponent)(() => ({
  width: '100%',
  height: 'auto',
}));

export const Figcaption = styled('figcaption')(() => ({
  marginTop: 12,
}));
