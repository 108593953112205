import React from 'react';
import { Flex, Box } from 'rebass';

import SwitchTheme from 'components/SwitchTheme';

import { SectionText, Wrap, Content, Decoration } from './SectionText.styled';

export default ({ content, className, themeName, decoration }) => {
  return (
    <SwitchTheme themeName={themeName}>
      <SectionText className={className}>
        <Decoration decoration={decoration} />
        <Wrap>
          <Flex justifyContent="center">
            <Box width={[1, null, null, null, 5 / 8]}>
              <Content content={content} />
            </Box>
          </Flex>
        </Wrap>
      </SectionText>
    </SwitchTheme>
  );
};
