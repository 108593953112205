import { colors } from 'config/variables';

export const theme = {
  name: 'green',
  type: 'dark',

  // global
  background: colors.greenPrimary,
  color: colors.greenPrimary,

  // component Text
  textPColor: colors.white,

  // component List
  listUlColor: colors.white,
  listUlLiBulletColor: colors.white,
  listOlColor: colors.white,
  listDlColor: colors.white,

  // component Headline
  headlineH1Color: colors.white,
  headlineH2Color: colors.white,
  headlineH3Color: colors.white,
  headlineH4Color: colors.white,
  headlineH5Color: colors.white,
  headlineH6Color: colors.white,

  // component Button
  buttonSolidColor: colors.white,
  buttonSolidBackground: colors.greenSecondary,
  buttonSolidBackgroundHover: colors.greenPrimary,
  buttonSolidColorHover: colors.white,
  buttonSolidOutlineColor: colors.greenSecondary,

  buttonGhostColor: colors.greenSecondary,
  buttonGhostBorderColor: colors.greenSecondary,
  buttonGhostBorderColorHover: colors.greenSecondary,
  buttonGhostBorderColorActive: colors.greenSecondary,
  buttonGhostColorHover: colors.greenSecondary,
  buttonGhostOutlineColor: colors.greenSecondary,

  buttonStrippedColor: colors.greenSecondary,
  buttonStrippedColorHover: colors.greenPrimary,
  buttonStrippedOutlineColor: colors.greenSecondary,
};
