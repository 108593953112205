import styled from '@emotion/styled';
import { media } from 'utils/styles';

export const MailchimpSignup = styled('form')({});

export const InputGroup = styled('div')({
  display: 'flex',
  marginTop: 33,
  flexDirection: 'column',
  [media('laptop')]: {
    width: 477,
    flexDirection: 'row',
    boxShadow: '0 3px 6px rgba(0, 0, 0, .07)',
  },
});

export const Input = styled('input')({
  padding: 26,
  font: 'inherit',
  lineHeight: 1,
  flexGrow: 1,
  border: '1px solid rgba(0, 0, 0, .1)',
  borderRadius: '5px',
  borderWidth: '1px',
  boxShadow: '0 3px 6px rgba(0, 0, 0, .07)',
  [media('laptop')]: {
    borderRadius: '5px 0 0 5px',
    borderWidth: '1px 0 1px 1px',
    boxShadow: 'none',
  },
});

export const SubmitButton = styled('input')(
  {
    padding: 26,
    [media('laptop')]: {
      padding: '26px 44px',
    },
    border: 0,
    WebkitAppearance: 'none',
    display: 'block',
    font: 'inherit',
    lineHeight: 1,
    cursor: 'pointer',
    transition: 'background-color 75ms ease-in-out',
    boxShadow: '0 3px 6px rgba(0, 0, 0, .07)',

    borderRadius: '5px',
    marginTop: '1rem',
    [media('laptop')]: {
      marginTop: 0,
      borderRadius: '0 5px 5px 0',
      boxShadow: 'none',
    },
  },
  props => ({
    backgroundColor: props.theme.greenSecondary,
    color: props.theme.white,
    ':hover': {
      backgroundColor: props.theme.greenPrimary,
    },
  }),
);

export const Button = styled('button')(
  {
    cursor: 'pointer',
    WebkitAppearance: 'none',
    border: 0,
    backgroundColor: 'transparent',
    font: 'inherit',
    fontSize: 12,
    padding: '8px 0',
    marginTop: 16,
    transition: 'color 75ms ease-in-out',
    boxShadow: 'inset 0 -1px 0 0 currentColor',
  },
  props => ({
    color: props.theme.greyTertiary,
    '&:hover': {
      color: props.theme.greenPrimary,
    },
  }),
);

SubmitButton.defaultProps = {
  type: 'submit',
};
