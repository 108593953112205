import styled from '@emotion/styled';

import LogoComponent from 'components/Logo';
import ImageComponent from 'components/Image';
import Link from 'components/Link';

export const LayoutNavSidebar = styled('div')(
  {
    position: 'relative',
    height: '100%',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '60%',
      paddingTop: '60%',
      height: 0,
      bottom: 0,
      left: 0,
      borderTopRightRadius: '100%',
    },
  },
  props => ({
    background: props.theme.white,
    '&:after': {
      background: props.theme.pinkPrimary,
    },
  }),
);

export const Logo = styled(LogoComponent)({
  position: 'absolute',
  top: 36,
  left: 24,
  height: 36,
});

export const Image = styled(ImageComponent)({
  width: '100%',
});

export const NavItem = styled(Link)(
  {
    display: 'block',
    fontWeight: 700,
    fontSize: 14,
    paddingTop: 12,
    paddingRight: 18,
    paddingBottom: 12,
    paddingLeft: 18,
    transition: 'color 0.2s ease-in-out',
    letterSpacing: 0.3,
    whiteSpace: 'nowrap',
  },
  props => ({
    borderBottom: `1px solid ${props.theme.greySecondary}`,
    color: props.theme.bluePrimary,
    fontFamily: props.theme.fontSecondary,
    textDecoration: 'none',
  }),
);
