import React from 'react';

import { getLinks } from 'utils/sanity';

import {
  LayoutNavSidebar,
  NavItem,
  Logo,
  Image,
} from './LayoutNavSidebar.styled';

export default ({ menu, menuImage }) => {
  return (
    <LayoutNavSidebar>
      {menuImage && <Image image={menuImage} />}
      <Logo />
      {menu?.map(menuItem => (
        <NavItem
          key={menuItem?._key}
          to={getLinks(menuItem)?.to}
          target={getLinks(menuItem)?.blank}
        >
          {menuItem?.title}
        </NavItem>
      ))}
    </LayoutNavSidebar>
  );
};
