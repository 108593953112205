import React from 'react';
import { Flex, Box } from 'rebass';

import { getLinks } from 'utils/sanity';

import Headline from 'components/Headline';
import Text from 'components/Text';
import Blockquote from 'components/Blockquote';
import Figure from 'components/Figure';
import Link from 'components/Link';
import List from 'components/List';
import ListItem from 'components/ListItem';
import SectionColumn from 'components/SectionColumn';
import SectionNewsletter from 'components/SectionNewsletter';
import SectionJob from 'components/SectionJob';
import SectionJobText from 'components/SectionJobText';
import SectionHero from 'components/SectionHero';
import SectionText from 'components/SectionText';
import SectionTextImage from 'components/SectionTextImage';
import SectionPerson from 'components/SectionPerson';
import Buttonset from 'components/Buttonset';
import Button from 'components/Button';

import { Wrap, Sections } from './Sections.styled';

const serializers = {
  list: props => {
    switch (props.type) {
      case 'bullet':
        return (
          <Wrap className="ul">
            <Flex justifyContent="center">
              <Box width={[1, null, null, 5 / 8]}>
                <List element="ul">{props.children}</List>
              </Box>
            </Flex>
          </Wrap>
        );

      case 'number':
        return (
          <Wrap className="ol">
            <Flex justifyContent="center">
              <Box width={[1, null, null, 5 / 8]}>
                <List element="ol">{props.children}</List>
              </Box>
            </Flex>
          </Wrap>
        );

      default:
        return null;
    }
  },
  listItem: props => {
    switch (props.node.listItem) {
      case 'number':
      case 'bullet':
        return <ListItem element="li">{props.children}</ListItem>;

      default:
        return null;
    }
  },
  marks: {
    link({ children, mark }) {
      return (
        <Link to={mark.href} target={mark.blank && '_blank'}>
          {children[0] || mark.href}
        </Link>
      );
    },
    linkInternal({ children, mark }) {
      return <Link to={mark.href}>{children[0] || mark.href}</Link>;
    },
  },
  types: {
    block(props) {
      switch (props.node.style) {
        case 'h1':
          return (
            <Wrap className="h1">
              <Flex justifyContent="center">
                <Box width={[1, null, null, 5 / 8]}>
                  <Headline element="h1">{props.children}</Headline>
                </Box>
              </Flex>
            </Wrap>
          );

        case 'h2':
          return (
            <Wrap className="h2">
              <Flex justifyContent="center">
                <Box width={[1, null, null, 5 / 8]}>
                  <Headline element="h2">{props.children}</Headline>
                </Box>
              </Flex>
            </Wrap>
          );

        case 'h3':
          return (
            <Wrap className="h3">
              <Flex justifyContent="center">
                <Box width={[1, null, null, 5 / 8]}>
                  <Headline element="h3">{props.children}</Headline>
                </Box>
              </Flex>
            </Wrap>
          );

        case 'h4':
          return (
            <Wrap className="h4">
              <Flex justifyContent="center">
                <Box width={[1, null, null, 5 / 8]}>
                  <Headline element="h4">{props.children}</Headline>
                </Box>
              </Flex>
            </Wrap>
          );

        case 'h5':
          return (
            <Wrap className="h5">
              <Flex justifyContent="center">
                <Box width={[1, null, null, 5 / 8]}>
                  <Headline element="h5">{props.children}</Headline>
                </Box>
              </Flex>
            </Wrap>
          );

        case 'h6':
          return (
            <Wrap className="h6">
              <Flex justifyContent="center">
                <Box width={[1, null, null, 5 / 8]}>
                  <Headline element="h6">{props.children}</Headline>
                </Box>
              </Flex>
            </Wrap>
          );

        case 'blockquote':
          return (
            <Wrap>
              <Flex justifyContent="center">
                <Box width={[1, null, null, 5 / 8]}>
                  <Blockquote>{props.children}</Blockquote>
                </Box>
              </Flex>
            </Wrap>
          );

        default:
          return (
            <Wrap className="p">
              <Flex justifyContent="center">
                <Box width={[1, null, null, 5 / 8]}>
                  <Text element="p">{props.children}</Text>
                </Box>
              </Flex>
            </Wrap>
          );
      }
    },
    figure({ node }) {
      return (
        <Wrap className="figure">
          <Flex alignItems="center">
            <Box width={[1, null, null, 5 / 8]}>
              <Figure {...node} />
            </Box>
          </Flex>
        </Wrap>
      );
    },
    sectionColumn({ node }) {
      return <SectionColumn {...node} />;
    },
    sectionNewsletter({ node }) {
      return <SectionNewsletter {...node} />;
    },
    sectionJob({ node }) {
      return <SectionJob {...node} />;
    },
    sectionJobText({ node }) {
      return <SectionJobText {...node} />;
    },
    sectionText({ node }) {
      return <SectionText {...node} />;
    },
    sectionTextImage({ node }) {
      return <SectionTextImage {...node} />;
    },
    sectionHero({ node }) {
      return <SectionHero {...node} />;
    },
    sectionPerson({ node }) {
      return <SectionPerson {...node} />;
    },
    // we have to keep this in since there is an type inheritance issue where the jobs type cant consume section block types ~DH
    buttonset({ node }) {
      const buttonAlign = node?.align;
      const buttonVariant = node?.buttonVariant;
      return buttonVariant ? (
        <Buttonset align={buttonAlign}>
          {buttonVariant.map(buttonType => (
            <Button
              key={buttonType._key}
              variant={buttonType?._type?.replace('button', '').toLowerCase()}
              themeName={buttonType.button.themeName}
              to={getLinks(buttonType?.button?.link)?.to}
              blank={getLinks(buttonType?.button?.link)?.blank}
            >
              {buttonType.button.title}
            </Button>
          ))}
        </Buttonset>
      ) : null;
    },
  },
};

export default ({ className, content, hasFooter, hasHeader }) => (
  <Sections
    className={className}
    blocks={content}
    serializers={serializers}
    hasFooter={hasFooter}
    hasHeader={hasHeader}
  />
);
