import React from 'react';

import { Figure, Image, Figcaption } from './Figure.styled.js';

export default props => {
  const asset = props?.asset;
  const caption = props?.caption;
  return (
    <Figure>
      {asset && <Image props={props} />}
      {caption && <Figcaption>{caption}</Figcaption>}
    </Figure>
  );
};
