import styled from '@emotion/styled';
import { media } from 'utils/styles';

import LinkClose from 'components/LinkClose';
import Modules from 'components/Modules';

export const Notice = styled('aside')(
  {
    position: 'fixed',
    zIndex: 2,
    top: 0,
    left: 0,
    width: 420,
    height: '100vh',
    maxWidth: '100vw',
    transition: 'box-shadow 3s ease-in-out',
    overflowY: 'scroll',

    [media('mobile')]: {
      overflowY: 'auto',
    },
  },
  props =>
    !props.customContent && {
      backgroundColor: props.theme.greySecondary,
      padding: '72px 36px',
      [media('tablet')]: {
        padding: '140px 64px',
      },
      h3: {
        marginBottom: 32,
      },
      'p + p': {
        marginTop: 16,
      },
      a: {
        textDecoration: 'underline',
      },
    },
);

export const Overlay = styled('div')(() => ({
  position: 'fixed',
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  transition: 'background-color 350ms ease-in-out',
}));

export const Content = styled(Modules)({});

export const Close = styled(LinkClose)(
  {
    top: 56,
    right: 36,
  },
  props => ({
    color: props.theme.greenSecondary,
    '&:hover': {
      color: props.theme.greyPrimary,
    },
  }),
);

export const Offset = styled('div')(({ push }) => ({
  [media('tablet')]: {
    transform: push && 'translateX(420px)',
    transition: 'transform 350ms ease-in-out',
  },
}));
