import React, { useState, useEffect } from 'react';
import cookie from 'react-cookies';
import { LayoutCookies, Link, Close } from './LayoutCookies.styled';

export default ({ text, linkUrl, linkLabel }) => {
  const [acceptedCookies, setAcceptedCookies] = useState(undefined);

  // Load cookies on mount
  // Doing this at this point makes sure, that there is no component flash on page load.
  useEffect(() => {
    setAcceptedCookies(cookie.load('accepted_cookies') === 'true');
  }, []);

  // Set cookie acceptance when it changes.
  useEffect(() => {
    if (acceptedCookies === true) {
      const expires = new Date();
      const now = new Date();
      expires.setDate(now.getDate() + 14);
      cookie.save('accepted_cookies', true, { expires, path: '/' });
    }
  }, [acceptedCookies]);

  // Only show cookie info when cookies got loaded
  // and the value is actually "false".
  if (acceptedCookies === false) {
    return (
      <LayoutCookies>
        {text}{' '}
        <Link to={linkUrl} ariaLabel="Zum Datenschutz">
          <strong>{linkLabel}</strong>
        </Link>
        .
        <Close onClick={() => setAcceptedCookies(true)} />
      </LayoutCookies>
    );
  }

  return null;
};
