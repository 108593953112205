import styled from '@emotion/styled';
import { media } from 'utils/styles';

import Link from 'components/Link';

export const LegalMenu = styled('nav')({
  marginTop: 32,
  [media('md')]: {
    marginTop: 0,
  },
  '& > *': {
    marginRight: 40,
    '&:last-child': {
      marginRight: 0,
    },
  },
});

export const MenuItem = styled(Link)(
  {
    color: 'inherit',
    transition: 'color 0.2s ease-in-out',
  },
  props => ({
    color: props.theme.legalMenuItemColor,
    '&:hover': {
      color: props.theme.legalMenuItemColorHover,
    },
  }),
);
