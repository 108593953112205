import React from 'react';
import { useLastSectionTheme } from 'utils/hooks';
import { Flex, Box } from 'rebass';

import Wrap from 'components/Wrap';
import Socials from 'components/Socials';
import LegalMenu from 'components/LegalMenu';
import SwitchTheme from 'components/SwitchTheme';

import { SectionFooter } from './SectionFooter.styled';

export default ({ className }) => {
  const lastSectionThemeName = useLastSectionTheme();
  return (
    <SwitchTheme themeName={lastSectionThemeName}>
      <SectionFooter className={className} element="footer">
        <Wrap>
          <Flex flexWrap="wrap" justifyContent="space-between">
            <Box width={[1, 1, 'auto']}>
              <Socials />
            </Box>
            <Box width={[1, 1, 'auto']}>
              <LegalMenu menuName="legal" />
            </Box>
          </Flex>
        </Wrap>
      </SectionFooter>
    </SwitchTheme>
  );
};
