import styled from '@emotion/styled';

// import { media } from 'utils/styles';

import ImageComponent from 'components/Image';
import Headline from 'components/Headline';

export const TilePerson = styled('div')({
  textAlign: 'center',
});

export const ImageWrap = styled('div')({
  position: 'relative',
  paddingTop: '120%',
});

export const Image = styled(ImageComponent)({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  obectFit: 'cover',
});

export const Name = styled(Headline)({
  paddingTop: 18,
});

export const Job = styled(Headline)({
  paddingTop: 12,
});
