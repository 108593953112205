import React from 'react';

import { TilePerson, ImageWrap, Image, Name, Job } from './TilePerson.styled';

export default ({ className, jobPosition, name, image }) => {
  return (
    <TilePerson className={className}>
      <ImageWrap>
        <Image image={image} />
      </ImageWrap>
      {name && <Name element="h4">{name}</Name>}
      {jobPosition && <Job element="h6">{jobPosition}</Job>}
    </TilePerson>
  );
};
