import styled from '@emotion/styled';

// triangle + square svg graphic bottom right
export const DecorationOne = styled('div')({
  position: 'absolute',
  right: 0,
  bottom: 0,
  width: 296,
  height: 480,
});

// half circle top right
export const DecorationTwo = styled('div')(
  {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '30vw',
    height: '30vw',
    borderBottomLeftRadius: '100%',
  },
  props => ({
    background: props.theme.background,
  }),
);

// half circle bottom right
export const DecorationThree = styled('div')(
  {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '30vw',
    height: '30vw',
    overflow: 'hidden',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: '-15vw',
      width: '100%',
      height: '100%',
      borderTopLeftRadius: '100%',
      borderBottomLeftRadius: '100%',
    },
  },
  props => ({
    '&:after': {
      background: props.theme.background,
    },
  }),
);

// quarter circle bottom left
export const DecorationFour = styled('div')(
  {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '15vw',
    height: '15vw',
    borderTopRightRadius: '100%',
  },
  props => ({
    background: props.theme.background,
  }),
);

// half circle right center
export const DecorationFive = styled('div')(
  {
    position: 'absolute',
    right: 0,
    top: '50%',
    width: '15vw',
    height: '40vw',
    transform: 'translateY(-50%)',
    overflow: 'hidden',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '40vw',
      height: '40vw',
      borderRadius: '100%',
    },
  },
  props => ({
    '&:after': {
      background: props.theme.background,
    },
  }),
);

// quarter circle bottom left large
export const DecorationSix = styled('div')(
  {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '40vw',
    height: '40vw',
    borderTopRightRadius: '100%',
  },
  props => ({
    background: props.theme.background,
  }),
);

// half circle right center small
export const DecorationSeven = styled('div')(
  {
    position: 'absolute',
    right: 0,
    top: '50%',
    width: '10vw',
    height: '30vw',
    transform: 'translateY(-50%)',
    overflow: 'hidden',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '30vw',
      height: '30vw',
      borderRadius: '100%',
    },
  },
  props => ({
    '&:after': {
      background: props.theme.background,
    },
  }),
);
