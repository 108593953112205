import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Box, Flex } from 'rebass';

import TileJob from 'components/TileJob';

import { TilesJob, Title } from './TilesJob.styled';

export default ({ className, title }) => {
  const query = useStaticQuery(graphql`
    {
      allSanityJob {
        edges {
          node {
            _id
            _rawTile
          }
        }
      }
    }
  `);
  const edges = query?.allSanityJob?.edges;

  return edges ? (
    <TilesJob className={className}>
      {title && <Title element="h3">{title}</Title>}
      <Flex flexWrap="wrap" mx={-3}>
        {edges?.map(edge => (
          <Box key={edge?.node?._id} width={[1, 1, 1, 1, 1 / 2]} px={3} pb={4}>
            <TileJob
              content={edge?.node?._rawTile?.content}
              image={edge?.node?._rawTile?.figure}
            />
          </Box>
        ))}
      </Flex>
    </TilesJob>
  ) : null;
};
