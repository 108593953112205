import React from 'react';

import {
  Locationselector,
  Input,
  InputWrap,
  InputIcon,
  Submit,
  SubmitIcon,
} from './Locationselector.styled';

export default ({
  className,
  placeholderText,
  submitUrl,
  submitText,
  themeName,
}) => (
  <Locationselector className={className}>
    <InputWrap>
      <InputIcon type="pinLocation" />
      <Input placeholder={placeholderText} disabled />
    </InputWrap>
    <Submit to={submitUrl} themeName={themeName}>
      {submitText} <SubmitIcon type="arrowRight" />
    </Submit>
  </Locationselector>
);
