import React from 'react';
import Helmet from 'react-helmet';
import { Global } from '@emotion/core';

import SwitchTheme from 'components/SwitchTheme';
import Seo from 'components/Seo';
import LayoutCookies from 'components/LayoutCookies';
import SectionHeader from 'components/SectionHeader';
import SectionFooter from 'components/SectionFooter';
import LayoutNotice from 'components/LayoutNotice';

import { styles } from './Layout.styles.js';
import { Layout } from './Layout.styled.js';

export default ({
  children,
  hasHeader,
  hasNavigation,
  hasFooter,
  currentTitle,
}) => {
  return (
    <SwitchTheme>
      <Global styles={styles} />
      <Helmet>
        <link
          rel="preload"
          href="fonts/proxima-nova-400.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="fonts/proxima-nova-600.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="fonts/proxima-nova-700.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="fonts/fieldwork-600.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="fonts/fieldwork-700.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
      </Helmet>
      <Seo currentTitle={currentTitle} />
      <LayoutNotice>
        <Layout>
          {hasHeader && <SectionHeader hasNavigation={hasNavigation} />}
          {children}
          {hasFooter && <SectionFooter />}
        </Layout>
      </LayoutNotice>
      <LayoutCookies
        text="Diese Webseite verwendet"
        linkLabel="Cookies"
        linkUrl="/datenschutz"
      />
    </SwitchTheme>
  );
};
