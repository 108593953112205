import styled from '@emotion/styled';
import { Box as BoxComponent } from 'rebass';

import Section from 'components/Section';
import Image from 'components/Image';
import Modules from 'components/Modules';
import WrapComponent from 'components/Wrap';

export const SectionHero = styled(Section)({
  display: 'flex',
  minHeight: '80vh',
  '&:only-of-type': {
    minHeight: '100vh', // if it is the only section element it should be fullscreen at least ~DH
  },
});

export const Wrap = styled(WrapComponent)({
  alignSelf: 'center',
});

export const Box = styled(BoxComponent)({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '1fr',
  gridTemplateAreas: '"content"',
  margin: '0 auto',
});

export const Content = styled(Modules)({
  gridArea: 'content',
  textAlign: 'center',
  h1: {
    marginBottom: 32,
  },
  h2: {
    marginBottom: 24,
  },
  p: {
    marginBottom: 18,
  },
  '.buttonset': {
    justifyContent: 'center',
  },
});

export const BackgroundImage = styled(Image)({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
});

export const Overlay = styled('div')({
  background: 'rgba(0,0,0,0.6)',
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
});
