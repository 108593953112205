import { useIntersectionObserver as useIntersectionObserverHook } from './useIntersectionObserver';
import { useOnClickOutside as useOnClickOutsideHook } from './useOnClickOutside';
import { useLockBodyScroll as useLockBodyScrollHook } from './useLockBodyScroll';
import { useScrollPosition as useScrollPositionHook } from './useScrollPosition';
import { useScrolledIntoView as useScrolledIntoViewHook } from './useScrolledIntoView';
import { useWindowSize as useWindowSizeHook } from './useWindowSize';
import { useLastSectionTheme as useLastSectionThemeHook } from './useLastSectionTheme';
import { useFirstSectionTheme as useFirstSectionThemeHook } from './useFirstSectionTheme';

export const useIntersectionObserver = useIntersectionObserverHook;
export const useOnClickOutside = useOnClickOutsideHook;
export const useLockBodyScroll = useLockBodyScrollHook;
export const useScrollPosition = useScrollPositionHook;
export const useWindowSize = useWindowSizeHook;
export const useScrolledIntoView = useScrolledIntoViewHook;
export const useLastSectionTheme = useLastSectionThemeHook;
export const useFirstSectionTheme = useFirstSectionThemeHook;
