import styled from '@emotion/styled';

import { media } from 'utils/styles';

import ImageComponent from 'components/Image';

// none (no decoration)

export const ImageDecorationNone = styled('div')({
  position: 'relative',
  paddingTop: '75%', // 4:3 ratio
  width: '100%',
});

export const ImageNone = styled(ImageComponent)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

export const ImageDecorationOne = styled('div')({
  position: 'relative',
  paddingTop: '120%',
  width: '80%',
});

// one (square left top / image vertical)

export const ImageOne = styled(ImageComponent)({
  position: 'absolute',
  bottom: 0,
  right: 0,
  width: '80%',
  height: '85%',
});

export const DecorationOne = styled('div')(
  {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '60%',
    height: '75%',
  },
  props => ({
    background: props.theme.color,
  }),
);

// two (square bottom right / image vertical)

export const ImageDecorationTwo = styled('div')({
  position: 'relative',
  paddingTop: '120%',
  width: '80%',
});

export const ImageTwo = styled(ImageComponent)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '80%',
  height: '85%',
});

export const DecorationTwo = styled('div')(
  {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '60%',
    height: '75%',
  },
  props => ({
    background: props.theme.color,
  }),
);

// three (multiple squares / image horizontal)
// @todo this should not stick out of grid area, but were moving fast ye? ~DH

export const ImageDecorationThree = styled('div')({
  position: 'relative',
  lineHeight: 0,
  display: 'inline-block',
  width: 240,
  height: 345,
  marginTop: 120,
  marginBottom: 72,
  [media('tablet')]: {
    justifySelf: 'center',
  },
  [media('laptop')]: {
    width: 320,
    height: 460,
    alignSelf: 'self-end',
    justifySelf: 'end',
    margin: 0,
  },
});

export const ImageThree = styled(ImageComponent)({
  userSelect: 'none',
  position: 'absolute !important',
  top: 0,
  left: 0,
  width: '100% !important',
  height: '100% !important',
});

export const DecorationThreeIchi = styled('div')(
  {
    position: 'absolute',
    top: '-10%',
    left: '-56%',
    height: '82%',
    width: '146%',
    zIndex: -1,
  },
  props => ({
    backgroundColor: props.theme.pinkPrimary,
  }),
);

export const DecorationThreeNi = styled('div')(
  {
    position: 'absolute',
    bottom: 0,
    left: '-28%',
    height: '41%',
    width: '100%',
    zIndex: -1,
  },
  props => ({
    backgroundColor: props.theme.greenPrimary,
  }),
);

export const DecorationThreeSan = styled('div')(
  {
    position: 'absolute',
    bottom: '-11%',
    right: '-9%',
    width: '31%',
    zIndex: -1,
    '&:before': {
      content: '""',
      display: 'block',
      paddingBottom: '100%',
    },
  },
  props => ({
    backgroundColor: props.theme.yellowPrimary,
  }),
);

export const DecorationThreeYon = styled('div')(
  {
    position: 'absolute',
    top: '-22%',
    left: '-42%',
    width: '96%',
    borderRadius: '50%',
    zIndex: -1,
    '&:before': {
      content: '""',
      display: 'block',
      paddingBottom: '100%',
    },
  },
  props => ({
    backgroundColor: props.theme.orangePrimary,
  }),
);
