import React from 'react';
import PropTypes from 'prop-types';

import { IconStyled } from './Icon.styled';
import * as IconMap from './Icon.map';

export default function Icon({
  type,
  width,
  height,
  viewBox,
  preserveAspectRatio,
  // For theming purposes ~DH
  fillPrimary,
  // fillSecondary,
  // fillTertiary,
  currentColor,
  className,
  title,
}) {
  const iconConfig = {
    arrowSelect: {
      icon: IconMap.ArrowSelect,
    },
    arrowRight: {
      icon: IconMap.ArrowRight,
    },
    pinLocation: {
      icon: IconMap.PinLocation,
      props: {
        viewBox: '0 0 24 24',
        width: '24',
        height: '24',
      },
    },
    socialLinkedin: {
      icon: IconMap.SocialLinkedin,
    },
    socialFacebook: {
      icon: IconMap.SocialFacebook,
      props: {
        viewBox: '0 0 16.719 30',
      },
    },
    socialFacebookSquare: {
      icon: IconMap.SocialFacebookSquare,
      props: {
        viewBox: '0 0 17 17',
      },
    },
    socialInstagram: {
      icon: IconMap.SocialInstagram,
      props: {
        viewBox: '0 0 17 17',
      },
    },
    socialInstagramFilled: {
      icon: IconMap.SocialInstagramFilled,
    },
    socialTwitter: {
      icon: IconMap.SocialTwitter,
      props: {
        viewBox: '0 0 36.918 30',
      },
    },
    socialYoutube: {
      icon: IconMap.SocialYoutube,
    },
    splitArrowUp: {
      icon: IconMap.SplitArrowUp,
      props: {
        viewBox: '0 0 64 64',
      },
    },
    splitArrowDown: {
      icon: IconMap.SplitArrowDown,
      props: {
        viewBox: '0 0 64 64',
      },
    },
    hamburger: {
      icon: IconMap.Hamburger,
    },
    personDeliver: {
      icon: IconMap.PersonDeliver,
      props: {
        viewBox: '0 0 34 36',
      },
    },
    arrowOr: {
      icon: IconMap.ArrowOr,
      props: {
        viewBox: '0 0 170 51',
      },
    },
    pin: {
      icon: IconMap.Pin,
      props: {
        viewBox: '0 0 36 50',
      },
    },
    close: {
      icon: IconMap.Close,
      props: {
        viewBox: '0 0 15.556 15.556',
      },
    },
  };

  // Avoid obsolete nesting within iconConfig if there are no settings ~DH
  const IconContent = iconConfig?.[type]?.icon || iconConfig?.[type];

  if (IconContent) {
    return (
      <IconStyled
        xmlns="http://www.w3.org/2000/svg"
        data-icon={type} // For debugging purposes ~DH
        width={width || iconConfig?.[type]?.props?.width || '24px'}
        height={height || iconConfig?.[type]?.props?.height || '24px'}
        viewBox={viewBox || iconConfig?.[type]?.props?.viewBox || '0 0 24 24'}
        preserveAspectRatio={preserveAspectRatio}
        className={className}
        title={title}
      >
        <IconContent
          // For theming purposes ~DH
          fillPrimary={currentColor ? 'currentColor' : fillPrimary}
          // fillSecondary={currentColor ? 'currentColor' : fillSecondary}
          // fillTertiary={fillTertiary}
        />
      </IconStyled>
    );
  }

  // Fallback if the iconMap doesnt match right so that the app does not crash ~DH
  console.warn(`Error occured while loading your Icon:'${type}'`);
  return null;
}

/* eslint-disable react/require-default-props */
// We set the default values/props for height, width and viewbox within component as fallback ~DH
Icon.propTypes = {
  type: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  viewBox: PropTypes.string,
  preserveAspectRatio: PropTypes.string,
  title: PropTypes.string,
  currentColor: PropTypes.bool,
  // For theming purposes ~DH
  fillPrimary: PropTypes.string,
  // fillSecondary: PropTypes.string,
  // fillTertiary: PropTypes.string,
};

Icon.defaultProps = {
  type: 'socialFacebook',
  preserveAspectRatio: 'xMidYMid meet',
  currentColor: true,
  // For theming purposes ~DH
  // fillPrimary: colorPrimary,
  // fillSecondary: colorSecondary,
  // fillTertiary: colorTertiary,
};
