import React from 'react';
import { Flex, Box } from 'rebass';

import SwitchTheme from 'components/SwitchTheme';
import TilesModules from 'components/TilesModules';

import {
  SectionColumn,
  Wrap,
  Content,
  Decoration,
} from './SectionColumn.styled';

export default ({ content, className, themeName, decoration, column }) => {
  return (
    <SwitchTheme themeName={themeName}>
      <SectionColumn className={className}>
        <Decoration decoration={decoration} />
        <Wrap>
          <Flex justifyContent="center" flexWrap="wrap">
            <Box width={[1, null, null, null, 5 / 8]} pb={5}>
              <Content content={content} />
            </Box>
            <TilesModules
              columns={column?.columns}
              fracture={column?.fracture}
            />
          </Flex>
        </Wrap>
      </SectionColumn>
    </SwitchTheme>
  );
};
