import React from 'react';

import { Link, LinkOutbound } from './Link.styled';

export default ({
  children,
  target,
  className,
  activeClassName = 'active',
  disabled,
  rel,
  to,
  onClick,
  ariaLabel,
}) => {
  const hasClickEvent = typeof onClick === 'function';

  if (!to && !hasClickEvent) {
    // In cases where a Link should be negated and just the styles should be passed, pass the styles as span ~DH
    return <span className={className}>{children}</span>;
  }

  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);

  // Use Gatsby <Link> for internal links.
  if (internal) {
    return (
      <Link
        aria-label={ariaLabel}
        data-internal // for debugging ~DH
        to={to}
        className={className}
        activeClassName={activeClassName}
        disabled={disabled}
        target={target}
        rel={rel}
        onClick={onClick}
      >
        {children}
      </Link>
    );
  }

  // Use Google <OutboundLink> for external links.
  return (
    <LinkOutbound
      aria-label={ariaLabel}
      data-external // for debugging ~DH
      href={to}
      target={typeof target === 'boolean' ? '' : target} // sanity can send false if not target should be defined, this can throw an type issue within outbound link component ~DH
      disabled={disabled}
      rel="noopener noreferrer"
      className={className}
      onClick={onClick}
    >
      {children}
    </LinkOutbound>
  );
};
