import {
  fonts,
  sizes,
  spaces,
  colors,
  indices,
  radices,
  breakpointsRebass,
} from 'config/variables';

export const theme = {
  name: 'bright',
  type: 'bright', // is the dominant color in the dark or the bright spectrum ? ~DH

  // global
  ...fonts,
  ...sizes,
  spaces,
  ...colors,
  ...indices,
  ...radices,
  background: 'transparent',
  colors: { ...colors },
  breakpoints: breakpointsRebass,
  spaceTop: 120,
  spaceTopLaptop: 180,
  spaceBottom: 180,
  spaceBottomLaptop: 120,

  // component Logo
  logoTextColor: colors.bluePrimary,

  // component Text
  textPColor: colors.greyQuaternary,

  // component List
  listUlColor: colors.greyQuaternary,
  listUlLiBulletColor: colors.greyPrimary,
  listOlColor: colors.greyQuaternary,
  listDlColor: colors.greyQuaternary,

  // component Headline
  headlineH1Color: colors.bluePrimary,
  headlineH2Color: colors.bluePrimary,
  headlineH3Color: colors.bluePrimary,
  headlineH4Color: colors.bluePrimary,
  headlineH5Color: colors.greenSecondary,
  headlineH6Color: colors.bluePrimary,

  // component Button
  buttonSolidColor: colors.white,
  buttonSolidBackground: colors.greyPrimary,
  buttonSolidBackgroundHover: colors.greyPrimary,
  buttonSolidColorHover: colors.white,
  buttonSolidOutlineColor: colors.greenSecondary,

  buttonGhostColor: colors.greyPrimary,
  buttonGhostBorderColor: colors.greyPrimary,
  buttonGhostBorderColorHover: colors.greyPrimary,
  buttonGhostBorderColorActive: colors.greyPrimary,
  buttonGhostColorHover: colors.greyPrimary,
  buttonGhostOutlineColor: colors.greenSecondary,

  buttonStrippedColor: colors.greyPrimary,
  buttonStrippedColorHover: colors.white,
  buttonStrippedOutlineColor: colors.greenSecondary,

  // social/s
  socialsTitleColor: colors.greyTertiary,
  socialColor: colors.greyPrimary,
  socialColorHover: colors.greenPrimary,

  // legalMenu
  legalMenuItemColor: colors.greyPrimary,
  legalMenuItemColorHover: colors.greenPrimary,

  // navigationPrimary
  navigationPrimaryColor: colors.greyTertiary,
  navigationPrimaryColorHover: colors.bluePrimary,
  navigationPrimaryColorActive: colors.bluePrimary,
};
