import styled from '@emotion/styled';

import SectionJobComponent from 'components/SectionJob';
import SectionTextComponent from 'components/SectionText';

// import { media } from 'utils/styles';

import Section from 'components/Section';

export const SectionJobText = styled(Section)({
  paddingTop: '0 !important',
  paddingBottom: '0 !important',
});

export const SectionJob = styled(SectionJobComponent)({
  zIndex: 2,
  paddingBottom: '0 !important',
});

export const SectionText = styled(SectionTextComponent)(
  {
    position: 'relative',
    zIndex: 1,
    '&:before': {
      // circle above section
      content: '""',
      position: 'absolute',
      bottom: '100%',
      left: 0,
      borderTopRightRadius: '100%',
      width: '40vw',
      height: '40vw',
    },
  },
  props => ({
    // auto calculated via spaceTop variable (you find that within default theme) ~DH
    marginTop: -props.theme.spaceTop,
    '&:before': {
      background: props.theme.blueSecondary,
    },
  }),
);
