import React from 'react';
import Layout from 'components/Layout';
import Sections from 'components/Sections';

export default ({ pageContext }) => {
  const { content, title, name, layout } = pageContext;

  // these have to be destructured and backed by hand since it can be that these
  // variables might not've been set in sanity cms by default. also we have to hand them
  // throu as single variables to make sure the site doesnt break in edge cases. ~DH
  const hasHeader = layout?.hasHeader || true;
  const hasNavigation = layout?.hasNavigation || true;
  const hasFooter = layout?.hasFooter || true;

  return content ? (
    <Layout
      currentTitle={title}
      currentName={name}
      hasHeader={hasHeader}
      hasFooter={hasFooter}
      hasNavigation={hasNavigation}
    >
      <Sections
        content={content}
        layout={layout}
        hasHeader={hasHeader}
        hasFooter={hasFooter}
      />
    </Layout>
  ) : null;
};
