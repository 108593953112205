import styled from '@emotion/styled';

import Link from 'components/Link';

export const Social = styled(Link)(
  {
    lineHeight: 1,
    fontSize: 20,
    verticalAlign: 'middle',
    color: 'inherit',
    transition: 'color 0.2s ease-in-out',
    svg: {
      fill: 'currentColor',
    },
  },
  props => ({
    color: props.theme.socialColor,
    '&:hover': {
      color: props.theme.socialColorHover,
    },
  }),
);
