import React from 'react';

import { Ul, Ol, Dl } from './List.styled';

export default ({ children, element, className }) => {
  let List;

  switch (element) {
    case 'ul':
      List = Ul;
      break;
    case 'ol':
      List = Ol;
      break;
    case 'dl':
    default:
      List = Dl;
      break;
  }

  return <List className={className}>{children}</List>;
};
