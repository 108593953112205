import styled from '@emotion/styled';

export const Buttonset = styled('div')(
  {
    display: 'flex',
    flexWrap: 'wrap',
    '> *': {
      marginTop: 6,
      marginBottom: 6,
    },
  },
  props =>
    props.align === 'right' && {
      justifyContent: 'flex-end',
      '> *': {
        marginLeft: 12,
      },
    },
  props =>
    props.align === 'left' && {
      justifyContent: 'flex-start',
      '> *': {
        marginRight: 12,
      },
      '> *:last-child': {
        marginRight: 0,
      },
    },
  props =>
    props.align === 'center' && {
      justifyContent: 'center',
      '> *': {
        marginLeft: 6,
        marginRight: 6,
      },
    },
  props =>
    props.align === 'between' && {
      justifyContent: 'space-between',
    },
  props =>
    props.align === 'around' && {
      justifyContent: 'space-around',
    },
);
