import React from 'react';

import { TileJob, Content, Image } from './TileJob.styled';

export default ({ className, content, image, to }) => {
  return (
    <TileJob to={to} className={className}>
      <Image image={image} />
      <Content content={content} />
    </TileJob>
  );
};
