import React from 'react';
import { ThemeProvider } from 'emotion-theming';

import { bright, dark, green, blue, blueSecondary, yellow, pink } from 'themes';

export default ({ themeName, children }) => {
  let theme;

  switch (themeName) {
    case 'bright':
    case 'none':
    default:
      theme = bright;
      break;

    case 'dark':
      theme = dark;
      break;

    case 'green':
      theme = green;
      break;

    case 'blue':
      theme = blue;
      break;

    case 'blueSecondary':
      theme = blueSecondary;
      break;

    case 'yellow':
      theme = yellow;
      break;

    case 'pink':
      theme = pink;
      break;
  }

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
