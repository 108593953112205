import React from 'react';

import {
  SectionNewsletter,
  Decoration,
  Wrap,
  Box,
  Content,
  ImageDecoration,
  Cta,
} from './SectionNewsletter.styled';

export default ({
  content,
  title,
  contentSidebar,
  imageDecoration,
  decoration,
}) => {
  return (
    <SectionNewsletter>
      <Decoration decoration={decoration} />
      <Wrap>
        <Box>
          <Content content={content} />
          <ImageDecoration imageDecoration={imageDecoration} />
          <Cta title={title} contentSidebar={contentSidebar} />
        </Box>
      </Wrap>
    </SectionNewsletter>
  );
};
