import React from 'react';

import { getLinks } from 'utils/sanity';

import Headline from 'components/Headline';
import Text from 'components/Text';
import Blockquote from 'components/Blockquote';
import Link from 'components/Link';
import List from 'components/List';
import ListItem from 'components/ListItem';
import Buttonset from 'components/Buttonset';
import Button from 'components/Button';
import Table from 'components/Table';
import Locationselector from 'components/Locationselector';

import { Modules, ModulesWrap, MarkIcon, MarkIconWrap } from './Modules.styled';

const serializers = {
  list: props => {
    switch (props.type) {
      case 'bullet':
        return <List element="ul">{props.children}</List>;

      case 'number':
        return <List element="ol">{props.children}</List>;

      default:
        return null;
    }
  },
  listItem: props => {
    switch (props.node.listItem) {
      case 'number':
      case 'bullet':
        return <ListItem element="li">{props.children}</ListItem>;

      default:
        return null;
    }
  },
  marks: {
    link({ children, mark }) {
      return (
        <Link to={mark.href} target={mark.blank && '_blank'}>
          {children[0] || mark.href}
        </Link>
      );
    },
    linkInternal({ children, mark }) {
      const { slug = {} } = mark;
      const to = `/${slug.current}`;
      return <Link to={to}>{children[0] || to}</Link>;
    },
    icon({ children, mark }) {
      return (
        <MarkIconWrap position={mark?.position} align={mark?.align}>
          <MarkIcon
            type={mark?.type}
            position={mark?.position}
            align={mark?.align}
          />
          {children[0]}
        </MarkIconWrap>
      );
    },
  },
  types: {
    block(props) {
      switch (props.node.style) {
        case 'h1':
          return <Headline element="h1">{props.children}</Headline>;

        case 'h2':
          return <Headline element="h2">{props.children}</Headline>;

        case 'h3':
          return <Headline element="h3">{props.children}</Headline>;

        case 'h4':
          return <Headline element="h4">{props.children}</Headline>;

        case 'h5':
          return <Headline element="h5">{props.children}</Headline>;

        case 'h6':
          return <Headline element="h6">{props.children}</Headline>;

        case 'blockquote':
          return <Blockquote>{props.children}</Blockquote>;

        default:
          return <Text element="p">{props.children}</Text>;
      }
    },
    buttonset({ node }) {
      const buttonAlign = node?.align;
      const buttonVariant = node?.buttonVariant;
      return buttonVariant ? (
        <Buttonset align={buttonAlign}>
          {buttonVariant.map(buttonType => (
            <Button
              key={buttonType._key}
              variant={buttonType?._type?.replace('button', '').toLowerCase()}
              themeName={buttonType.button.themeName}
              to={getLinks(buttonType?.button?.link)?.to}
              blank={getLinks(buttonType?.button?.link)?.blank}
            >
              {buttonType.button.title}
            </Button>
          ))}
        </Buttonset>
      ) : null;
    },
    timetable({ node }) {
      return <Table {...node} />;
    },
    locationselector({ node }) {
      return <Locationselector {...node} />;
    },
  },
};

// wrap is nessessary because "Modules" is sometimes a fragment
// sometimes a div depending on child node count
// https://github.com/sanity-io/block-content-to-html/issues/7 ~DH

export default ({ className, content }) => (
  <ModulesWrap className={className}>
    <Modules blocks={content} serializers={serializers} />
  </ModulesWrap>
);
