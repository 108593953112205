import styled from '@emotion/styled';

export const P = styled('p')(
  {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    fontStyle: 'inherit',
    textTransform: 'inherit',
  },
  props => ({
    color: props.theme.textPColor,
  }),
);

export const Div = styled('div')({});
export const Span = styled('span')({});
export const Strong = styled('strong')({
  fontWeight: 700,
});
export const Em = styled('em')({});
export const Code = styled('code')({});
