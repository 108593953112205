import styled from '@emotion/styled';
import { media } from 'utils/styles';
import Section from 'components/Section';

import WrapComponent from 'components/Wrap';
import ImageDecorationComponent from 'components/ImageDecoration';
import MailchimpSignup from 'components/MailchimpSignup';
import Modules from 'components/Modules';
import DecorationComponent from 'components/Decoration';

export const SectionNewsletter = styled(Section)({
  display: 'flex',
});

export const Wrap = styled(WrapComponent)({
  alignSelf: 'center',
});

export const Decoration = styled(DecorationComponent)({});

export const Box = styled('div')({
  display: 'grid',
  gridGap: 24,
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'fit-content() 1fr fit-content()',
  gridTemplateAreas: `'content'
                      'decoration'
                      'cta'`,
  [media('laptop')]: {
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    gridTemplateAreas: `'content decoration'
                        'cta     decoration'`,
  },
  h3: {
    marginBottom: 32,
  },
  h5: {
    marginBottom: 24,
  },
});

export const Content = styled(Modules)({
  gridArea: 'content',
  alignSelf: 'end',
});

export const ImageDecoration = styled(ImageDecorationComponent)({
  gridArea: 'decoration',
  alignSelf: 'center',
  justifySelf: 'center',
});

export const Cta = styled(MailchimpSignup)({
  gridArea: 'cta',
  [media('tablet')]: {
    alignSelf: 'self-end',
  },
});
