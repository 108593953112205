import styled from '@emotion/styled';
import { colors } from 'config/variables';
import LinkComponent from 'components/Link';
import LinkClose from 'components/LinkClose';

export const LayoutCookies = styled('div')({
  position: 'fixed',
  bottom: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: colors.white,
  zIndex: 100,
  border: `1px solid ${colors.greyPrimary}`,
  borderWidth: '1px 1px 0 1px',
  paddingTop: 16,
  paddingRight: 48,
  paddingBottom: 16,
  paddingLeft: 36,
  maxWidth: 480,
  whiteSpace: 'nowrap',
  fontSize: 12,
});

export const Close = styled(LinkClose)({
  marginLeft: 24,
  marginRight: 12,
  svg: {
    width: 12,
  },
});

export const Link = styled(LinkComponent)({});
