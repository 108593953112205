import React from 'react';
import { Flex } from 'rebass';

import { fractures } from 'utils/sanity';

import {
  Box,
  TilesModules,
  Content,
  Decoration,
  Icon,
  IconText,
} from './TilesModules.styled';

export default ({ fracture, columns, className }) => {
  return columns ? (
    <TilesModules className={className}>
      <Flex flexWrap="wrap" mx={-4}>
        {columns?.map(column => (
          <Box key={column?._key} width={fractures(fracture)} px={4} pb={6}>
            {column?.hasDecoration && (
              <Decoration>
                <Icon type="arrowOr" />
                <IconText>oder</IconText>
              </Decoration>
            )}
            <Content content={column?.content} />
          </Box>
        ))}
      </Flex>
    </TilesModules>
  ) : null;
};
