import styled from '@emotion/styled';

export const Ul = styled('ul')(
  {
    paddingLeft: 36,
    listStyle: 'none',
    li: {
      '&:before': {
        content: '"•"',
        width: 16,
        marginLeft: -16,
        fontWeight: 700,
        display: 'inline-block',
      },
    },
  },
  props => ({
    color: props.theme.listUlColor,
    li: {
      '&:before': {
        color: props.theme.listUlLiBulletColor,
      },
    },
  }),
);

export const Ol = styled('ol')(
  {
    paddingLeft: 36,
  },
  props => ({
    color: props.theme.listOlColor,
  }),
);

export const Dl = styled('dl')(
  {
    paddingLeft: 36,
  },
  props => ({
    color: props.theme.listDlColor,
  }),
);
