import styled from '@emotion/styled';
import { media } from 'utils/styles';

import Icon from 'components/Icon';
import Button from 'components/Button';

export const Locationselector = styled('div')({
  marginTop: 48,
  display: 'flex',
  flexDirection: 'column',
  [media('laptop')]: {
    flexDirection: 'row',
    boxShadow: '0 3px 6px rgba(0, 0, 0, .07)',
  },
});

export const InputWrap = styled('div')({
  position: 'relative',
  flexGrow: 1,
  borderRadius: 4,
});

export const InputIcon = styled(Icon)(
  {
    position: 'absolute',
    left: 18,
    top: '50%',
    transform: 'translateY(-50%)',
    height: 24,
    width: 24,
  },
  props => ({ color: props.theme.greyTertiary }),
);

export const Input = styled('input')({
  paddingLeft: 48,
  paddingTop: 24,
  paddingBottom: 24,
  paddingRight: 36,
  font: 'inherit',
  lineHeight: 1,
  border: '1px solid rgba(0, 0, 0, .1)',
  borderRadius: 'inherit',
  borderWidth: 1,
  width: '100%',
  height: '100%',
  boxShadow: '0 3px 6px rgba(0, 0, 0, .07)',
  [media('laptop')]: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderWidth: '1px 0 1px 1px',
    boxShadow: 'none',
  },
});

export const Submit = styled(Button)({
  boxShadow: '0 3px 6px rgba(0, 0, 0, .07)',
  paddingTop: 24,
  paddingBottom: 24,
  paddingLeft: 48,
  paddingRight: 48,
  marginTop: 18,
  [media('laptop')]: {
    marginTop: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    boxShadow: 'none',
  },
});

export const SubmitIcon = styled(Icon)(
  {
    marginTop: -6,
    marginBottom: -7,
  },
  props => ({
    color: props.theme.white,
  }),
);
