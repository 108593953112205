import React, { useRef, useLayoutEffect, useState } from 'react';

import { buildImageObj, imageUrlFor } from 'utils/sanity';

import { Image } from './Image.styled.js';

export default ({ className, image }) => {
  const asset = image?.asset;
  const alt = image?.alt;
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      });
    }
  }, []);

  // just an intermediate solution until gatsby image reintegration.
  // causes two paints/requests with 120 small version and
  // second with the size of first viewport image size ~DH
  const win = typeof window === 'object' ? window : {};
  const imageWidth = dimensions?.width * (win?.devicePixelRatio || 1) || 120;

  return asset ? (
    <Image
      ref={targetRef}
      className={className}
      src={imageUrlFor(buildImageObj(image))
        .width(imageWidth)
        .url()}
      alt={alt}
    />
  ) : null;
};
