import { css } from '@emotion/core';
import { colors, fonts } from 'config/variables';

// local import since no way to specify font-display on @font-face
// https://github.com/typekit/webfontloader/issues/409
// old embed via typekit @import url('https://use.typekit.net/${process.env.GATSBY_TYPEKIT_ID}.css'); ~DH

export const styles = css`
  @font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-display: 'swap';
    src: local('Proxima Nova'),
      url('fonts/proxima-nova-400.woff2') format('woff2'),
      url('fonts/proxima-nova-400.woff') format('woff'),
      url('fonts/proxima-nova-400.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-display: 'swap';
    src: local('Proxima Nova'),
      url('fonts/proxima-nova-600.woff2') format('woff2'),
      url('fonts/proxima-nova-600.woff') format('woff'),
      url('fonts/proxima-nova-600.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-display: 'swap';
    src: local('Proxima Nova'),
      url('fonts/proxima-nova-700.woff2') format('woff2'),
      url('fonts/proxima-nova-700.woff') format('woff'),
      url('fonts/proxima-nova-700.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Fieldwork';
    font-style: normal;
    font-weight: 600;
    font-display: 'swap';
    src: local('Fieldwork'), url('fonts/fieldwork-600.woff2') format('woff2'),
      url('fonts/fieldwork-600.woff') format('woff'),
      url('fonts/fieldwork-600.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Fieldwork';
    font-style: normal;
    font-weight: 700;
    font-display: 'swap';
    src: local('Fieldwork'), url('fonts/fieldwork-700.woff2') format('woff2'),
      url('fonts/fieldwork-700.woff') format('woff'),
      url('fonts/fieldwork-700.ttf') format('truetype');
  }

  *,
  *:before,
  *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline-color: ${colors.greenSecondary};
  }

  html {
    color: ${colors.greyPrimary};
    font-size: 16px;
    font-weight: 400;
    line-height: 1.875;
    box-sizing: border-box;
    overflow-y: scroll;
  }

  body {
    font-family: ${fonts.fontPrimary};
    -webkit-font-smoothing: antialiased;
  }
`;
