import { useState, useLayoutEffect } from 'react';

// Hook
export const useScrollPosition = () => {
  const isClient = typeof document === 'object';

  function getPosition() {
    return isClient
      ? document.documentElement.scrollTop || document.body.scrollTop
      : 0;
  }

  const [scrollPosition, setScrollPosition] = useState(getPosition);

  function handleScrollPosition() {
    setScrollPosition(getPosition());
  }

  useLayoutEffect(() => {
    if (!isClient) {
      return;
    }

    window.addEventListener('scroll', handleScrollPosition);
    return () => window.removeEventListener('scroll', handleScrollPosition);
  });

  return scrollPosition || getPosition();
};

// Usage
// function App() {
//   const scrollPosition = useScrollPosition();

//   return (
//     <div>
//       {scrollPosition.scrollPosition}px
//     </div>
//   );
// }
