import { colors } from 'config/variables';

export const theme = {
  name: 'blue',
  type: 'dark',

  // global
  background: colors.bluePrimary,

  // component Text
  textPColor: colors.white,

  // component List
  listUlColor: colors.white,
  listUlLiBulletColor: colors.white,
  listOlColor: colors.white,
  listDlColor: colors.white,

  // component Headline
  headlineH1Color: colors.greenSecondary,
  headlineH2Color: colors.white,
  headlineH3Color: colors.white,
  headlineH4Color: colors.white,
  headlineH5Color: colors.white,
  headlineH6Color: colors.white,

  // component Button
  buttonSolidColor: colors.white,
  buttonSolidBackground: colors.bluePrimary,
  buttonSolidBackgroundHover: colors.bluePrimary,
  buttonSolidColorHover: colors.white,
  buttonSolidOutlineColor: colors.bluePrimary,

  buttonGhostColor: colors.bluePrimary,
  buttonGhostBorderColor: colors.bluePrimary,
  buttonGhostBorderColorHover: colors.bluePrimary,
  buttonGhostBorderColorActive: colors.bluePrimary,
  buttonGhostColorHover: colors.bluePrimary,
  buttonGhostOutlineColor: colors.bluePrimary,

  buttonStrippedColor: colors.bluePrimary,
  buttonStrippedColorHover: colors.bluePrimary,
  buttonStrippedOutlineColor: colors.bluePrimary,
};
