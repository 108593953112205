import styled from '@emotion/styled';

import { media } from 'utils/styles';

import Link from 'components/Link';
import Modules from 'components/Modules';
import ImageComponent from 'components/Image';

export const TileJob = styled(Link)({
  display: 'block',
  position: 'relative',
  background: '#fff',
  minHeight: 264,
  borderRadius: 4,
  boxShadow: '3px 6px 12px rgba(0,0,0,0.07)',
  overflow: 'hidden',
});

export const Content = styled(Modules)({
  paddingTop: 36,
  paddingLeft: 18,
  paddingRight: 18,
  paddingBottom: 18,
  [media('tablet')]: {
    paddingLeft: 36,
    paddingRight: '40%',
  },
  h4: {
    marginBottom: 18,
  },
  p: {
    marginBottom: 12,
  },
});

export const Image = styled(ImageComponent)({
  width: '100%',
  [media('tablet')]: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '35%',
    height: '100%',
  },
});
