import styled from '@emotion/styled';

import Headline from 'components/Headline';

export const TilesJob = styled('div')({
  marginTop: 36,
});

export const Title = styled(Headline)({
  paddingBottom: 24,
});
