import React, { useContext } from 'react';

import { LayoutNoticeContext } from 'components/LayoutNotice';
import Headline from 'components/Headline';

import {
  MailchimpSignup,
  SubmitButton,
  Input,
  InputGroup,
  Button,
} from './MailchimpSignup.styled';

export default ({ className, title, contentSidebar }) => {
  const notice = useContext(LayoutNoticeContext);
  return (
    <MailchimpSignup
      action="https://cudino.us3.list-manage.com/subscribe/post?u=b3fc71b43d72c7dec1dbbb5d3&amp;id=296cad0d53"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      className={`${className} validate`}
      target="_blank"
    >
      {title && <Headline element="h6">{title}</Headline>}
      <InputGroup>
        <Input
          type="email"
          name="EMAIL"
          className="required email"
          id="mce-EMAIL"
          placeholder="Deine Mail Adresse"
          aria-label="Email Adresse für Newsletter eingeben."
          required
        />
        <SubmitButton
          value="Anmelden"
          name="subscribe"
          aria-label="Mit eingegebener E-mail den Newsletter abonnieren."
          id="mc-embedded-subscribe"
        />
      </InputGroup>
      <Button
        onClick={e => {
          e.preventDefault();
          notice.setShow(true);
          notice.setCustomContent(false);
          notice.setContent(contentSidebar);
        }}
      >
        Was passiert mit meinen Daten?
      </Button>
      <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
        <input
          type="text"
          name="b_b3fc71b43d72c7dec1dbbb5d3_296cad0d53"
          tabIndex="-1"
          defaultValue=""
        />
      </div>
    </MailchimpSignup>
  );
};

MailchimpSignup.propTypes = {};

MailchimpSignup.defaultProps = {};
