import styled from '@emotion/styled';
import { Link as GatsbyLink } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const styles = props => ({
  textDecoration: 'none',
  // padding-top: 0.2em;
  // padding-bottom: 0.2em;
  verticalAlign: 'bottom',
  color: props.theme.greenSecondary,
  '&:hover': {
    // boxShadow: 'inset 0 -0.1em 0 0 currentColor',
    color: props.theme.greenPrimary,
  },
  cursor: props.onClick && 'pointer', // polyfill if link has onClick event ~DH
});

export const Link = styled(GatsbyLink)(props => styles(props));

export const LinkOutbound = styled(OutboundLink)(props => styles(props));
