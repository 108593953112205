import React from 'react';
import { Flex, Box } from 'rebass';

import {
  SectionJob,
  Decoration,
  Wrap,
  Grid,
  Content,
  TilesJob,
} from './SectionJob.styled';

export default ({
  className,
  content,
  contentTextAlign = 'center',
  decoration,
  tilesTitle,
}) => {
  return (
    <SectionJob className={className} decoration={decoration}>
      <Decoration decoration={decoration} />
      <Wrap>
        <Grid>
          <Flex justifyContent="center">
            <Box width={[1, null, 5 / 8]} textAlign={contentTextAlign}>
              <Content content={content} />
            </Box>
          </Flex>
          <TilesJob title={tilesTitle} />
        </Grid>
      </Wrap>
    </SectionJob>
  );
};
