import styled from '@emotion/styled';

import Link from 'components/Link';
import Icon from 'components/Icon';

import { media } from 'utils/styles';

export const LayoutNav = styled('nav')({
  display: 'flex',
  alignItems: 'center',
});

export const BurgerIcon = styled(Icon)({
  userSelect: 'none',
});

export const NavItem = styled(Link)(
  {
    fontWeight: 700,
    transition: 'color 0.2s ease-in-out',
    letterSpacing: 0.3,
    whiteSpace: 'nowrap',
    fontSize: 14,
    marginRight: 36,
    '&:last-of-type': {
      marginRight: 0,
    },
    [media('zero', 'tablet')]: {
      display: 'none',
    },
  },
  props => ({
    color: props.theme.navigationPrimaryColor,
    fontFamily: props.theme.fontSecondary,
    '&:hover': {
      color: props.theme.navigationPrimaryColorHover,
    },
    '&.active': {
      color: props.theme.navigationPrimaryColorActive,
    },
  }),
);

export const NavItemBurger = styled(NavItem)({
  [media('zero', 'laptop')]: {
    display: 'block',
  },
  [media('laptop')]: {
    display: 'none',
  },
});
