import React from 'react';

import SwitchTheme from 'components/SwitchTheme';

import {
  DecorationOne,
  DecorationTwo,
  DecorationThree,
  DecorationFour,
  DecorationFive,
  DecorationSix,
  DecorationSeven,
} from './Decoration.styled';

export default ({ className, decoration }) => {
  const type = decoration?.type;
  const themeName = decoration?.themeName;

  let Decoration;
  let content;

  switch (type) {
    default:
    case 'none':
      Decoration = () => null;
      break;

    case 'one':
      Decoration = DecorationOne;
      content = (
        <svg viewBox="0 0 296 480">
          <path fill="#008281" d="M163.5 480L0 309v171z" />
          <path fill="#f4e200" d="M296 480V0L0 309.5 163.1 480z" />
        </svg>
      );
      break;

    case 'two':
      Decoration = DecorationTwo;
      break;

    case 'three':
      Decoration = DecorationThree;
      break;

    case 'four':
      Decoration = DecorationFour;
      break;

    case 'five':
      Decoration = DecorationFive;
      break;

    case 'six':
      Decoration = DecorationSix;
      break;

    case 'seven':
      Decoration = DecorationSeven;
      break;
  }
  return (
    <SwitchTheme themeName={themeName}>
      <Decoration className={className}>{content}</Decoration>
    </SwitchTheme>
  );
};
