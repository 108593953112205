import React from 'react';

import SwitchTheme from 'components/SwitchTheme';

import {
  SectionTextImage,
  Grid,
  Content,
  ImageDecoration,
  Decoration,
} from './SectionTextImage.styled';

export default props => {
  const {
    content,
    className,
    themeName,
    reverse,
    decoration,
    imageDecoration,
  } = props;
  return (
    <SwitchTheme themeName={themeName}>
      <SectionTextImage className={className}>
        <Decoration decoration={decoration} />
        <Grid reverse={reverse}>
          <Content content={content} />
          <ImageDecoration
            reverse={reverse}
            imageDecoration={imageDecoration}
          />
        </Grid>
      </SectionTextImage>
    </SwitchTheme>
  );
};
