import React from 'react';
import { Flex, Box } from 'rebass';

import {
  SectionPerson,
  Wrap,
  Grid,
  Content,
  TilesPerson,
  Decoration,
} from './SectionPerson.styled';

export default ({
  className,
  content,
  contentTextAlign = 'center',
  tilesTitle,
  decoration,
}) => {
  return (
    <SectionPerson className={className}>
      <Decoration decoration={decoration} />
      <Wrap>
        <Grid>
          <Flex justifyContent="center">
            <Box width={[1, null, 5 / 8]} textAlign={contentTextAlign}>
              <Content content={content} />
            </Box>
          </Flex>
          <TilesPerson title={tilesTitle} />
        </Grid>
      </Wrap>
    </SectionPerson>
  );
};
