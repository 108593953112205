import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Social from 'components/Social';

import { Socials, Title } from './Socials.styled';

export default className => (
  <StaticQuery
    query={graphql`
      query SocialQuery {
        socials: sanityCompanyInfo(_id: { regex: "/(drafts.|)companyInfo/" }) {
          __typename
          social {
            title
            socialItemType {
              __typename
              ... on SanitySocialItemFacebook {
                _key
                socialItem {
                  url
                }
              }
              ... on SanitySocialItemInstagram {
                _key
                socialItem {
                  url
                }
              }
              ... on SanitySocialItemTwitter {
                _key
                socialItem {
                  url
                }
              }
            }
          }
        }
      }
    `}
    render={({ socials }) => {
      const title = socials?.social?.title;
      const socialItems = socials?.social?.socialItemType;

      return socialItems ? (
        <Socials className={className}>
          {title && <Title>{title}</Title>}
          {socialItems.map(({ _key, __typename, socialItem }) => (
            <Social key={_key} type={__typename} url={socialItem?.url} />
          ))}
        </Socials>
      ) : null;
    }}
  />
);
