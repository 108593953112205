import styled from '@emotion/styled';
import { Box as BoxRebass, Flex as FlexRebass } from 'rebass';

import { media } from 'utils/styles';

import WrapComponent from 'components/Wrap';
import LogoComponent from 'components/Logo';

export const SectionHeader = styled('header')(
  {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: '2',
    height: 72,
    [media('laptop')]: {
      height: 120,
    },
  },
  props =>
    props.sticky && {
      position: 'fixed',
      background: props.theme.white,
      boxShadow: '0 0 6px rgba(0,0,0,0.05)',
      height: 72,
      [media('laptop')]: {
        height: 72,
      },
    },
);

export const Wrap = styled(WrapComponent)({
  height: 'inherit',
});

export const Flex = styled(FlexRebass)(
  {
    height: 'inherit',
  },
  props =>
    props.sticky && {
      alignItems: 'center',
    },
);

export const Box = styled(BoxRebass)({
  lineHeight: 1,
});

export const Logo = styled(LogoComponent)(
  {
    height: 36,
    transition: '0.1s height ease-in-out',
    [media('laptop')]: {
      height: 54,
    },
  },
  props =>
    props.sticky && {
      height: 36,
      [media('laptop')]: {
        height: 36,
      },
    },
);
