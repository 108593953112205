import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

export default ({
  description = '',
  lang = '',
  meta = [],
  keywords = [],
  currentTitle,
}) => (
  <StaticQuery
    query={graphql`
      query SEOQuery {
        site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
          title
          description
          keywords
          author
          lang
        }
      }
    `}
    render={data => {
      if (!data.site) {
        return;
      }
      const siteLanguages = lang || data.site.lang;
      const metaDescription = description || data.site.description;
      return (
        <Helmet
          htmlAttributes={{
            lang: siteLanguages,
          }}
          title={currentTitle}
          titleTemplate={
            currentTitle === data.site.title ? '%s' : `${data.site.title} | %s`
          }
          meta={[
            {
              name: 'description',
              content: metaDescription,
            },
            {
              property: 'og:title',
              content: currentTitle,
            },
            {
              property: 'og:description',
              content: metaDescription,
            },
            {
              property: 'og:type',
              content: 'website',
            },
            {
              name: 'twitter:card',
              content: 'summary',
            },
            {
              name: 'twitter:creator',
              content: data.site.author,
            },
            {
              name: 'twitter:title',
              content: currentTitle,
            },
            {
              name: 'twitter:description',
              content: metaDescription,
            },
          ]
            .concat(
              keywords && keywords.length > 0
                ? {
                    name: 'keywords',
                    content: keywords.join(', '),
                  }
                : [],
            )
            .concat(meta)}
        />
      );
    }}
  />
);
