import styled from '@emotion/styled';

import TilesJobComponent from 'components/TilesJob';
import Section from 'components/Section';

import WrapComponent from 'components/Wrap';
import DecorationComponent from 'components/Decoration';
import Modules from 'components/Modules';

export const SectionJob = styled(Section)({
  display: 'flex',
  position: 'relative',
});

export const Decoration = styled(DecorationComponent)({});

export const Wrap = styled(WrapComponent)({
  alignSelf: 'center',
  position: 'relative',
});

export const Grid = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'fit-content() 2fr',
  gridTemplateAreas: `'content'
                      'tiles'`,
});

export const Content = styled(Modules)({
  gridArea: 'content',
  h2: {
    marginBottom: 24,
  },
  h3: {
    marginBottom: 18,
  },
  p: {
    marginBottom: 12,
  },
  'p + h3': {
    marginTop: 48,
  },
  '.buttonset': {
    marginTop: 36,
    marginBottom: 36,
  },
});

export const TilesJob = styled(TilesJobComponent)({
  gridArea: 'tiles',
});
