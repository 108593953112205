import React, { useState, useRef, useCallback } from 'react';
import { Portal } from 'react-portal';

import { useOnClickOutside, useLockBodyScroll } from 'utils/hooks';

import { LayoutNoticeContext } from 'components/LayoutNotice';
import { Close, Offset, Notice, Content, Overlay } from './LayoutNotice.styled';

export default ({ children }) => {
  const [show, setShow] = useState(false);
  const [customContent, setCustomContent] = useState(false);
  const [content, setContent] = useState(false);

  const NoticeRef = useRef();
  const setShowFalse = useCallback(() => {
    setShow(false);
  }, []);

  useOnClickOutside(NoticeRef, setShowFalse);
  useLockBodyScroll(show);

  return (
    <LayoutNoticeContext.Provider
      value={{ show, setShow, content, setContent, setCustomContent }}
    >
      <>
        {show && (
          <Portal>
            <Notice ref={NoticeRef} customContent={customContent}>
              {customContent ? content : <Content content={content} />}
              <Close
                onClick={e => {
                  e.preventDefault();
                  setShow(false);
                }}
                disabled={!show}
              />
            </Notice>
          </Portal>
        )}
        <Offset push={show}>
          {children}
          {show && <Overlay />}
        </Offset>
      </>
    </LayoutNoticeContext.Provider>
  );
};
