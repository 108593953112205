import React from 'react';
import { useTheme } from 'emotion-theming';

import {
  Logo,
  LogoImage,
  LogoText,
  LogoImageText,
  LogoResponsive,
  Desktop,
  Mobile,
} from './Logo.styled';

export default ({ to = '/', type, className }) => {
  const theme = useTheme();
  switch (type) {
    case 'image':
      return (
        <Logo ariaLabel="Logo" to={to} className={className}>
          <LogoImage xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <path
              d="M19,36.12c0,.24,0,.49,0,.74A10.19,10.19,0,0,0,29.13,47a10.4,10.4,0,0,0,4.18-.9h0a9.89,9.89,0,0,0,1.58-.9Z"
              fill="#016c46"
            />
            <path
              d="M29.11,41.92V26.68A10.17,10.17,0,0,0,19,36.12Z"
              fill="#f4e200"
            />
            <path d="M19,36.4v-.16A.43.43,0,0,1,19,36.4Z" fill="#f4e200" />
            <path
              d="M19,36.85a5.94,5.94,0,0,1,0-.73L2.49,26.68a21.37,21.37,0,0,0,8,17.2A24,24,0,0,0,33.3,46.12,10.17,10.17,0,0,1,19,36.85Z"
              fill="#0d213f"
            />
            <path d="M33.31,46.12Z" fill="#0d213f" />
            <path
              d="M9.16,13.37A30.08,30.08,0,0,1,8.69,1.46S24-3.16,35,4.05c0,0,11.1,6.59,10.45,22.54l-13.41.09h-3c-6.15,0-16.76-1.6-20-13.31Z"
              fill="#0d213f"
            />
            <path
              d="M32.89,15.12a3.68,3.68,0,1,1-3.67-3.67A3.67,3.67,0,0,1,32.89,15.12Z"
              fill="#0d213f"
            />
            <path
              d="M9.18,13.34h0C6.08,16,3.46,20.2,2.52,26.64l24.89,0c-.41,0-.94-.06-1.57-.13C19.74,25.83,11.86,23.15,9.18,13.34Z"
              fill="#ff531c"
            />
            <path
              d="M19,36.12h0A10.17,10.17,0,0,1,29,26.68h0c-.61,0-1.27,0-1.95-.07l-18.55,0-6,.05Z"
              fill="#ffa3bd"
            />
            <path
              d="M29.12,26.68V41.92l5.77,3.31a10.17,10.17,0,0,0-5.77-18.54Z"
              fill="#ff531c"
            />
            <path
              d="M34.42,9.93a7.35,7.35,0,1,0,0,10.4A7.36,7.36,0,0,0,34.42,9.93Zm-5.2,8.88a3.68,3.68,0,1,1,3.67-3.68,3.68,3.68,0,0,1-3.67,3.68Z"
              fill="#fff"
            />
          </LogoImage>
        </Logo>
      );

    case 'text':
      return (
        <Logo ariaLabel="Logo" to={to} className={className}>
          <LogoText
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 118.61 28.37"
          >
            <path
              fill="#0d213f"
              d="M10.63,8.61a14.57,14.57,0,0,1,6,1.27l-.43,4.41a12.55,12.55,0,0,0-5-1c-3.46,0-6.1,1.77-6.1,5.19,0,3.24,2.51,5.26,5.94,5.26a12,12,0,0,0,5.22-1.25L16.83,27a14.4,14.4,0,0,1-6.14,1.4c-6.24,0-10.69-4-10.69-9.9C0,12.31,4.5,8.61,10.63,8.61Z"
            />
            <path
              fill="#0d213f"
              d="M32.89,9H38V20.17c0,4.8-3.61,8.2-9,8.2S20.11,25,20.11,20.29V9H25.2V19.66a3.85,3.85,0,1,0,7.7,0Z"
            />
            <path
              fill="#0d213f"
              d="M41.49,18.47c0-5.77,3.81-9.86,9.19-9.86a7.51,7.51,0,0,1,6.26,2.69V.14h5V28h-5l-.1-2a7.93,7.93,0,0,1-6.17,2.41C45.3,28.37,41.49,24.2,41.49,18.47Zm10.23-5.31a5,5,0,0,0-5.14,5.29,5.15,5.15,0,1,0,10.29,0,5.09,5.09,0,0,0-5.15-5.29Z"
            />
            <path
              fill="#0d213f"
              d="M66.32,2.92a2.92,2.92,0,1,1,5.83,0,2.92,2.92,0,0,1-5.83,0Zm.37,25V9h5.07V28Z"
            />
            <path
              fill="#0d213f"
              d="M94.81,16.06V28H89.73V17.36a3.72,3.72,0,0,0-3.91-4,4,4,0,0,0-4.21,4V28H76.55V9h4.82l.1,2.19a7.24,7.24,0,0,1,5.85-2.59C91.7,8.61,94.81,11.5,94.81,16.06Z"
            />
            <path
              fill="#0d213f"
              d="M108.48,8.61a9.79,9.79,0,0,1,10.13,9.88,10.14,10.14,0,0,1-20.27,0A9.79,9.79,0,0,1,108.48,8.61Zm0,15.18a5,5,0,0,0,5.09-5.3,5.1,5.1,0,1,0-10.19,0A5,5,0,0,0,108.48,23.79Z"
            />
          </LogoText>
        </Logo>
      );

    case 'imageText':
    default:
      return (
        <Logo ariaLabel="Logo" to={to} className={className}>
          <LogoImageText
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 176.8 48"
          >
            <path
              d="M68.84,17.21a14.57,14.57,0,0,1,6,1.27l-.43,4.41a12.94,12.94,0,0,0-5-1c-3.46,0-6.1,1.78-6.1,5.2,0,3.24,2.5,5.25,5.94,5.25a11.94,11.94,0,0,0,5.22-1.24l.59,4.5A14.29,14.29,0,0,1,68.9,37c-6.25,0-10.69-4-10.69-9.89C58.21,20.91,62.71,17.21,68.84,17.21Z"
              fill={theme.logoTextColor}
            />
            <path
              d="M91.1,17.61h5.05V28.77c0,4.8-3.61,8.19-9,8.19s-8.86-3.35-8.86-8.07V17.61h5.07V28.25a3.86,3.86,0,1,0,7.71,0Z"
              fill={theme.logoTextColor}
            />
            <path
              d="M99.69,27.07c0-5.77,3.81-9.86,9.19-9.86a7.47,7.47,0,0,1,6.25,2.69V8.74h5V36.57h-5l-.09-2a7.93,7.93,0,0,1-6.17,2.4C103.5,37,99.69,32.8,99.69,27.07Zm10.23-5.31a5,5,0,0,0-5.13,5.29,5.14,5.14,0,1,0,10.28,0,5.09,5.09,0,0,0-5.15-5.29Z"
              fill={theme.logoTextColor}
            />
            <path
              d="M124.52,11.52a2.91,2.91,0,1,1,5.82,0,2.91,2.91,0,1,1-5.82,0Zm.36,25.05v-19H130v19Z"
              fill={theme.logoTextColor}
            />
            <path
              d="M153,24.66V36.57h-5.08V26a3.72,3.72,0,0,0-3.91-4,4,4,0,0,0-4.21,4V36.57h-5.05v-19h4.81l.1,2.19a7.24,7.24,0,0,1,5.85-2.59C149.9,17.21,153,20.1,153,24.66Z"
              fill={theme.logoTextColor}
            />
            <path
              d="M166.67,17.21a9.88,9.88,0,1,1-10.14,9.87A9.79,9.79,0,0,1,166.67,17.21Zm0,15.17a5,5,0,0,0,5.09-5.29,5.1,5.1,0,1,0-10.19,0A5,5,0,0,0,166.67,32.38Z"
              fill={theme.logoTextColor}
            />
            <path
              d="M16.49,36.12c0,.24,0,.49,0,.74A10.18,10.18,0,0,0,26.64,47a10.36,10.36,0,0,0,4.18-.9h0a9.89,9.89,0,0,0,1.58-.9Z"
              fill="#016c46"
            />
            <path
              d="M26.63,41.92V26.68a10.18,10.18,0,0,0-10.15,9.44Z"
              fill="#f4e200"
            />
            <path d="M16.47,36.4v0Z" fill="#f4e200" />
            <path
              d="M16.47,36.85c0-.24,0-.49,0-.73L0,26.68a21.39,21.39,0,0,0,8.05,17.2,24,24,0,0,0,22.76,2.24,10.17,10.17,0,0,1-14.34-9.27Z"
              fill="#0d213f"
            />
            <path d="M30.83,46.12Z" fill="#0d213f" />
            <path
              d="M6.67,13.37A29.91,29.91,0,0,1,6.21,1.46S21.47-3.16,32.55,4.05c0,0,11.1,6.59,10.45,22.54l-13.4.09h-3c-6.16,0-16.77-1.6-20-13.31Z"
              fill="#0d213f"
            />
            <path
              d="M30.41,15.12a3.68,3.68,0,1,1-3.68-3.67A3.68,3.68,0,0,1,30.41,15.12Z"
              fill="#0d213f"
            />
            <path
              d="M6.69,13.34h0C3.59,16,1,20.2,0,26.64l24.89,0c-.41,0-.94-.06-1.57-.13C17.25,25.83,9.37,23.15,6.69,13.34Z"
              fill="#ff531c"
            />
            <path
              d="M16.49,36.12h0a10.17,10.17,0,0,1,10.06-9.44h0c-.61,0-1.26,0-1.95-.07l-18.55,0-6,.05Z"
              fill="#ffa3bd"
            />
            <path
              d="M26.64,26.68V41.92l5.76,3.31a10.17,10.17,0,0,0-5.76-18.54Z"
              fill="#ff531c"
            />
            <path
              d="M31.93,9.93a7.35,7.35,0,1,0,0,10.4A7.36,7.36,0,0,0,31.93,9.93Zm-5.2,8.88a3.68,3.68,0,1,1,3.68-3.68,3.68,3.68,0,0,1-3.68,3.68Z"
              fill="#fff"
            />
          </LogoImageText>
        </Logo>
      );
    case 'responsive':
      return (
        <Logo ariaLabel="Logo" to={to} className={className}>
          <LogoResponsive>
            <Desktop>
              <LogoText
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 118.61 28.37"
              />
            </Desktop>
            <Mobile>
              <LogoImage
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
              />
              <LogoText
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 118.61 28.37"
              />
            </Mobile>
          </LogoResponsive>
        </Logo>
      );
  }
};
