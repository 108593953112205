import styled from '@emotion/styled';
import { media } from 'utils/styles';
import { Box as BoxComponent } from 'rebass';

import Modules from 'components/Modules';
import IconComponent from 'components/Icon';

export const Box = styled(BoxComponent)({});

export const TilesModules = styled('div')({
  [Box]: {
    position: 'relative',
  },
});

export const Content = styled(Modules)({
  textAlign: 'center',
  h1: {
    paddingBottom: 24,
  },
  h2: {
    paddingBottom: 24,
  },
  h3: {
    paddingBottom: 24,
  },
  h4: {
    paddingBottom: 24,
  },
});

export const Decoration = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
});

export const Icon = styled(IconComponent)(
  {
    display: 'none',
    [media('tablet')]: {
      display: 'block',
    },
    position: 'absolute',
    bottom: '100%',
    left: '100%',
    width: 170,
    height: 102,
    transform: 'translateX(-50%)',
  },
  props => ({ color: props.theme.greyTertiary }),
);

export const IconText = styled('div')(
  {
    position: 'absolute',
    top: 'calc(100% - 72px)',
    left: '50%',
    transform: 'translateX(-50%)',
    textAlign: 'center',
    fontWeight: 700,
    fontSize: 20,
    [media('tablet')]: {
      top: 0,
      left: '100%',
      transform: 'translate(-50%, -50%)',
    },
  },
  props => ({
    fontFamily: props.theme.fontSecondary,
    color: props.theme.greyTertiary,
  }),
);
