import styled from '@emotion/styled';
import BaseBlockContent from '@sanity/block-content-to-react';

import Icon from 'components/Icon';

export const ModulesWrap = styled('div')({});

export const Modules = styled(BaseBlockContent)({});

export const MarkIconWrap = styled('span')(
  {
    position: 'relative',
    display: 'inline-flex',
    justifyItems: 'center',
    alignItems: 'center',
  },
  props => ({
    textAlign: props.align || 'left',
    flexDirection:
      (props.position === 'top' && 'column') ||
      (props.position === 'bottom' && 'column-reverse'),
  }),
);

export const MarkIcon = styled(Icon)(
  {
    height: 36,
    width: 36,
  },
  props => ({
    marginTop: props.position === 'bottom' ? 18 : 0,
    marginRight: props.position === 'left' ? 18 : 0,
    marginBottom: props.position === 'top' ? 18 : 0,
    marginLeft: props.position === 'right' ? 18 : 0,
    color: props.theme.greenSecondary,
  }),
);
