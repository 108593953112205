import styled from '@emotion/styled';
import { media } from 'utils/styles';

import Link from 'components/Link';

export const Logo = styled(Link)({
  display: 'inline-block',
});

export const LogoImage = styled('svg')({
  height: 'inherit',
});

export const LogoImageText = styled('svg')({
  height: 'inherit',
});

export const LogoText = styled('svg')({
  height: 'inherit',
});

export const LogoResponsive = styled('div')({});

export const Desktop = styled('div')({
  display: 'none',
  [media('laptop')]: {
    display: 'flex',
  },
});

export const Mobile = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  '>*:first-of-type': {
    marginBottom: 24,
  },
  [media('laptop')]: {
    display: 'none',
  },
});
