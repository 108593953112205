import styled from '@emotion/styled';

import { media } from 'utils/styles';

import LinkComponent from 'components/Link';

const buttonStyles = {
  border: 0,
  borderRadius: 4,
  userSelect: 'none',
  fontSize: 14,
  lineHeight: '24px',
  fontWeight: 600,
  paddingTop: 12,
  paddingRight: 24,
  paddingLeft: 24,
  paddingBottom: 12,
  display: 'inline-block',
  textDecoration: 'none',
  appearance: 'none',
  verticalAlign: 'bottom',
};

const buttonVariantSolidStyles = props => ({
  fontFamily: props.theme.fontPrimary,
  color: props.theme.buttonSolidColor,
  background: props.theme.buttonSolidBackground,
  transition: '0.2s background ease-in-out',
  '&:hover': {
    color: props.theme.buttonSolidColorHover,
    background: props.theme.buttonSolidBackgroundHover,
  },
  '&:active, &.active': {},
  '&:focus': {
    outlineColor: props.theme.buttonSolidOutlineColor,
  },
  opacity: props.disabled && 0.3,
  '&:disabled': {
    opacity: 0.3,
  },
});

const buttonVariantGhostStyles = props => ({
  fontFamily: props.theme.fontPrimary,
  color: props.theme.buttonGhostColor,
  background: 'transparent',
  border: `1px solid ${props.theme.buttonGhostBorderColor}`,
  paddingTop: 11,
  paddingRight: 23,
  paddingBottom: 11,
  paddingLeft: 23,
  '&:hover': {
    color: props.theme.buttonGhostColorHover,
    borderColor: props.theme.buttonGhostBorderColorHover,
  },
  '&:active, &.active': {
    borderColor: props.theme.buttonGhostBorderColorActive,
  },
  '&:focus': {
    borderColor: props.theme.buttonGhostBorderColorFocus,
    outlineColor: props.theme.buttonGhostOutlineColor,
  },
  opacity: props.disabled && 0.3,
  '&:disabled': {
    opacity: 0.3,
  },
});

const buttonVariantStrippedStyles = props => ({
  fontFamily: props.theme.fontPrimary,
  color: props.theme.buttonStrippedColor,
  background: 'transparent',
  paddingRight: 12,
  paddingLeft: 12,
  [media('tablet')]: {
    paddingRight: 24,
    paddingLeft: 24,
  },
  '&:hover': {
    color: props.theme.buttonStrippedColorHover,
  },
  '&:focus': {
    outlineColor: props.theme.buttonStrippedOutlineColor,
  },
  opacity: props.disabled && 0.3,
  '&:disabled': {
    opacity: 0.3,
  },
});

export const Input = styled('input')(
  { ...buttonStyles },
  props =>
    props.variant === 'solid' && {
      ...buttonVariantSolidStyles(props),
    },
  props =>
    props.variant === 'ghost' && {
      ...buttonVariantGhostStyles(props),
    },
  props =>
    props.variant === 'stripped' && {
      ...buttonVariantStrippedStyles(props),
    },
);

export const Link = styled(LinkComponent)(
  { ...buttonStyles },
  props =>
    props.variant === 'solid' && {
      ...buttonVariantSolidStyles(props),
    },
  props =>
    props.variant === 'ghost' && {
      ...buttonVariantGhostStyles(props),
    },
  props =>
    props.variant === 'stripped' && {
      ...buttonVariantStrippedStyles(props),
    },
);

export const Button = styled('button')(
  { ...buttonStyles },
  props =>
    props.variant === 'solid' && {
      ...buttonVariantSolidStyles(props),
    },
  props =>
    props.variant === 'ghost' && {
      ...buttonVariantGhostStyles(props),
    },
  props =>
    props.variant === 'stripped' && {
      ...buttonVariantStrippedStyles(props),
    },
);
